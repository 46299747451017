






import Vue from 'vue';
import { Search } from '@/components/icon/icons';

export default Vue.extend({
  components: {
    Search,
  },

  props: {
    value: String,
  },

  computed: {
    inputValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string): void {
        this.$emit('input', newValue);
      },
    },
  },
});
