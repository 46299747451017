import Vue from 'vue';
import { AppError } from '@/exceptions';

const fbLogin = (params: { scope: string }) => new Promise<fb.StatusResponse>((resolve, reject) => {
  try {
    FB.login((response) => {
      resolve(response);
    }, params);
  } catch (e) {
    console.error(`FB login is failed with error: ${e}`);
    // @ts-ignore
    console.error(`Failure extra data: ${e.message}`);
    reject(e);
  }
});

const fbGetLoginStatus = () => new Promise<fb.StatusResponse>((resolve, reject) => {
  try {
    FB.getLoginStatus((response) => resolve(response), true);
  } catch (e) {
    console.error(`FB login status check is failed with error: ${e}`);
    // @ts-ignore
    console.error(`Failure extra data: ${e.message}`);
    reject(e);
  }
});

export default Vue.extend({
  mounted() {
    (this.$el as HTMLInputElement).disabled = true;
    const src = 'https://connect.facebook.net/en_US/sdk.js';
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('src', src);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('crossorigin', 'anonymous');
    document.head.appendChild(script);
    script.onload = () => {
      try {
        FB.init({
          appId: '390091648720159',
          version: 'v9.0',
        });
        (this.$el as HTMLInputElement).disabled = false;
      } catch (e) {
        console.log(`Exception is occurred during Facebook API loading: ${JSON.stringify(e)}`);
        this.$nextTick(() => {
          throw new AppError('Exception is occurred during Facebook API loading.');
        });
      }
    };
  },
  methods: {
    async authenticate() {
      try {
        const { status, authResponse } = await fbGetLoginStatus();
        console.log(`User ID taken from login status check is: ${authResponse && authResponse.userID}`);

        if (status === 'connected') {
          this.$emit('completed', { provider: 'fb', token: authResponse.accessToken });
          return;
        }

        const loginResponse = await fbLogin({ scope: 'email' });
        if (loginResponse.status === 'connected') {
          console.log(`User ID taken from login is: ${
            loginResponse.authResponse && loginResponse.authResponse.userID
          }`);
          this.$emit('completed', { provider: 'fb', state: 'junk', token: loginResponse.authResponse.accessToken });
          return;
        }
        console.log('Could not login current session with Facebook.');
      } catch (e) {
        console.log(`Exception is occurred during authentication via Facebook: ${e}.`);
      }

      this.$nextTick(() => {
        throw new AppError('Exception is occurred during authentication via Facebook.');
      });
    },
  },
});
