





























import Vue from 'vue';
import { CircleStencil, Cropper, Preview } from 'vue-advanced-cropper';
import { AppError } from '@/exceptions';

type Fallback = 'image/png' | 'image/gif' | 'image/jpeg' | string | null;

function getMimeType(file: ArrayBuffer, fallback: string | null = null): Fallback {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i += 1) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

interface ImagePreview {
  coordinates: object;
  image: object;
}

interface State {
  image: {
    src: string;
    type: string | null;
  },
  preview: ImagePreview,
}

export default Vue.extend({
  components: {
    CircleStencil,
    Cropper,
    Preview,
  },

  data(): State {
    return {
      image: {
        src: '',
        type: '',
      },
      preview: {
        coordinates: {},
        image: {},
      },
    };
  },

  methods: {
    isImage(name: string): boolean {
      return ['jpg', 'jpeg', 'png'].includes(name.slice(name.lastIndexOf('.') + 1).toLowerCase());
    },
    loadFile(event: { target: { files: FileList; }; }) {
      const { files } = event.target;
      if (files && files[0] && this.isImage(files[0].name)) {
        // Destroy loaded file before
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target?.result) {
            this.image = {
              src: blob,
              type: getMimeType(e.target.result as ArrayBuffer, files[0].type),
            };
          }
        };
        (this.$refs.label as HTMLElement).textContent = event.target.files[0].name;
        reader.readAsArrayBuffer(files[0]);
      } else {
        throw new AppError('Unsupported file type, choose either ".jpg", ".jpeg" or ".png"');
      }
    },
    onChange({ coordinates, image }: ImagePreview) {
      this.preview = {
        coordinates,
        image,
      };
      const result = (this.$refs.cropper as InstanceType<typeof Cropper>).getResult();
      if (result.canvas && this.image.type) {
        this.$emit('set-avatar', result.canvas.toDataURL(this.image.type));
      }
    },
  },
});
