import { Module } from 'vuex';
import type { Store } from '@/main/store/store-types';
import {
  AccountData, AvatarType, BusinessUnit,
} from '@/generated/api-client';

const account: Module<AccountData, Store> = {
  namespaced: true,

  state: (): AccountData => ({

    name: '',
    email: '',
    company: '',
    phone: '',
    businessUnits: [] as BusinessUnit[],
    avatar: {
      id: 0,
      content: '',
      type: AvatarType.Svg,
    },
    notifyStart: false,
    notifyFinish: false,
    notifyError: false,
    notifyAbort: false,
    notifyPause: false,
    notifyResume: false,
  }),

  mutations: {
    setInfo(state, info) {
      state.name = info.name;
      state.email = info.email;
      state.company = info.company;
      state.phone = info.phone;
      state.businessUnits = info.businessUnits;
      state.avatar = info.avatar;
      state.notifyStart = info.notifyStart;
      state.notifyFinish = info.notifyFinish;
      state.notifyError = info.notifyError;
      state.notifyAbort = info.notifyAbort;
      state.notifyPause = info.notifyPause;
      state.notifyResume = info.notifyResume;
    },
  },
};

export default account;
