interface Pagination {
  skip: number;
  limit: number;
  page?: number;
  itemsOnPage?: number;
}

type PageSize = 10 | 25 | 50;

const createPaginationOptions = (): PageSize[] => [10, 25, 50];

class Paging {
  public readonly currentPage: number;

  public readonly itemsPerPage: PageSize;

  constructor(currentPage: number, itemsPerPage?: PageSize) {
    if (currentPage < 1) {
      throw new Error(`Invalid page number: ${currentPage}`);
    }
    this.currentPage = currentPage;
    this.itemsPerPage = itemsPerPage || createPaginationOptions()[0];
  }

  get skip() {
    return (this.currentPage - 1) * this.itemsPerPage;
  }

  get limit() {
    return this.itemsPerPage;
  }
}

const createPagination = (skip:number, limit:number):Pagination => ({
  skip, limit,
});

export {
  Pagination, createPagination, PageSize, Paging, createPaginationOptions,
};
