








import Vue from 'vue';
import inputlabel from '../input/input-label.vue';
import inputerror from '../input/input-error.vue';

export default Vue.extend({
  components: {
    inputlabel,
    inputerror,
  },
  props: {
    id: String,
    label: String,
    value: String,
    error: String,
  },
  computed: {
    textareaValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit('input', newValue);
        this.$emit('update:error', '');
      },
    },
  },
});
