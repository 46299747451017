import { ActionContext, Module } from 'vuex';
import type { PaymentsModule, Store } from '@/main/store/store-types';
import { createClient, Payment, RequestApiCallParams } from '../plugins/paymentsApiClient';

const api = createClient();

const printHistory: Module<PaymentsModule, Store> = {
  namespaced: true,

  state: (): PaymentsModule => ({
    payments: [],
    count: 0,
  }),

  getters: {
    getPayments(state): Array<Payment> {
      return state.payments;
    },
    getPaymentsCount(state):number {
      return state.count;
    },
  },

  mutations: {
    setPayments(state, value: Array<Payment>): void {
      state.payments = value;
    },
    setPaymentsCount(state, value: number): void {
      state.count = value;
    },
  },

  actions: {
    async requestPayments(
      { commit, getters }: ActionContext<PaymentsModule, Store>,
      query: RequestApiCallParams = {},
    ) {
      const { data, count } = await api.request(query);
      commit('setPayments', data);
      commit('setPaymentsCount', count);
    },
  },
};

export default printHistory;
