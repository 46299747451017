const groupBySize = <T>(arr: T[], size: number): T[][] => {
  if (!arr) return [];
  const firstChunk = arr.slice(0, size);
  if (!firstChunk.length) {
    // @ts-ignore
    return arr;
  }
  return [firstChunk].concat(groupBySize(arr.slice(size, arr.length), size));
};

export default groupBySize;
