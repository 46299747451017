import Vue, { PropType } from 'vue';
import Icon from '../icon/icon.vue';
import { AccountData } from '@/generated/api-client';

interface State {
  smallMenuExpanded: boolean;
}

export default Vue.extend({
  components: {
    Icon,
  },

  props: {
    account: Object as PropType<AccountData>,
    items: Array as PropType<Array<{ to: string, name: string }>>,
    defaultItemIndex: {
      type: Number,
      default: 0,
    },
    accountRoute: String,
    staticLinks: Array as PropType<Array<{ to: string, name: string }>>,
  },

  data(): State {
    return {
      smallMenuExpanded: false,
    };
  },

  methods: {
    goTo() {
      if (this.smallMenuExpanded) {
        this.expandSmallMenu();
      }
    },
    expandSmallMenu() {
      this.smallMenuExpanded = !this.smallMenuExpanded;
    },
    logOut() {
      this.$emit('logout');
    },
  },
});
