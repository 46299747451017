import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';

const REFRESH_TIME = 30000;

const recurrentRefresh = async (
  context: CombinedVueInstance<Vue, object, object, object, object>,
  callback: () => Promise<void>,
  interval: number = REFRESH_TIME,
) => {
  let timeout: NodeJS.Timeout;
  let canceled = false;

  const run = async () => {
    clearTimeout(timeout);
    try {
      await callback();
    } finally {
      if (!canceled) {
        timeout = setTimeout(run, interval);
      }
    }
  };

  context.$once('hook:beforeDestroy', () => {
    clearTimeout(timeout);
    canceled = true;
  });

  await run();

  return run;
};

export default recurrentRefresh;
