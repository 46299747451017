















import Vue from 'vue';

export default Vue.extend({
  props: {
    id: String,
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: Boolean,
    label: {
      type: String,
      required: false,
    },
  },
  computed: {
    checkboxValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean): void {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    onChange(event) {
      this.$emit('onChange', event.target.checked);
    },
    onClick() {
      this.$emit('onClick');
    },
  },
});

