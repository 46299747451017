import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import checkbox from '../checkbox/checkbox.vue';
import { SortArrow } from '@/components/icon/icons';
import {
  Header, Sorting, sorting, defaultSorting,
} from './header';
import { SortingOrder } from '@/generated/api-client';

export default Vue.extend({
  components: {
    checkbox,
    SortArrow,
  },

  props: {
    headers: {
      type: Array as PropType<Array<Header<any, any>>>,
      required: true,
    },
    sorting: {
      type: Object as PropType<Sorting<any, SortingOrder>>,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    sortBy(i: number) {
      const header = this.headers[i];
      if (!header.sortName) {
        return;
      }
      const sortingOrder = this.indexOfSelectedHeader === i
        ? this.sorting.sortingOrder === -1
          ? 1
          : -1
        : 1;

      this.$emit('sorting-changed', sorting(this.headers[i].sortName, sortingOrder));
    },
    getClassObject(i: number) {
      return {
        active: this.indexOfSelectedHeader === i,
        [this.headers[i].hide]: true,
      };
    },
    ...mapActions('historyActions', ['toggleSelectableItems']),
  },

  computed: {
    indexOfSelectedHeader(): number {
      // sortingColumn may match sortName in several headers (if hide property for some screen sizes is provided)
      return this.headers.findIndex((h) => h.sortName === this.sorting.sortingColumn
        // @ts-ignore
        && h.breakpoints.includes(this.$screen.breakpoint));
    },
    arrowUp(): boolean {
      return this.sorting.sortingOrder === 1;
    },
    ...mapGetters('historyActions', ['getEnableSelection', 'getSelectAllStatus']),
  },

  watch: {
    '$screen.breakpoint': function f(): void {
      this.$emit('sorting-changed', defaultSorting(this.headers, this.$screen.breakpoint!));
    },
  },
});
