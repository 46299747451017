import { render, staticRenderFns } from "./table-header-2.vue?vue&type=template&id=b7005fdc&lang=pug"
import script from "./table-header-2.ts?vue&type=script&lang=ts&external"
export * from "./table-header-2.ts?vue&type=script&lang=ts&external"
import style0 from "./table-header-2.vue?vue&type=style&index=0&id=b7005fdc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports