type HorizontalAlignment = 'left' | 'center' | 'right';
type Breakpoint = 'xl' | 'lg' | 'md' | 'sm';
interface Header<Columns, SortingOrder> {
  headerName: string;
  sortName: Columns;
  visible: boolean;
  hide: string;
  defaultSorting?: Array<Breakpoint>;
  defaultOrder?: SortingOrder;
  hAlign: HorizontalAlignment;
  breakpoints: Breakpoint[];
}

interface Sorting<Column extends string, SortingOrder> {
  sortingColumn: Column;
  sortingOrder: SortingOrder;
}

const createBreakpoints = (hide: string): Breakpoint[] => {
  switch (hide) {
    case 'hide-on-desktop':
      return ['lg', 'md', 'sm'];
    case 'hide-on-tablet':
      return ['xl', 'sm'];
    case 'hide-on-mobile':
      return ['xl', 'lg', 'md'];
    case 'hide-on-desktop-tablet':
      return ['sm'];
    case 'hide-on-tablet-mobile':
      return ['xl'];
    default:
      return ['xl', 'lg', 'md', 'sm'];
  }
};

const header = <Columns, SortingOrder>(
  name:string, sortName:Columns, visible:boolean, hide:string, align:HorizontalAlignment = 'left',
):Header<Columns, SortingOrder> => ({
    headerName: name,
    sortName,
    hide,
    hAlign: align,
    visible,
    breakpoints: createBreakpoints(hide),
  });

const sorting = <Column extends string, SortingOrder>(column:Column, order:SortingOrder) => ({
  sortingColumn: column, sortingOrder: order,
});

const defaultSorting = <Columns, SortingOrder>(headers: Header<Columns, SortingOrder>[], breakpoint: string) => {
  const defaultHeader = headers.find((h) => h.defaultSorting?.includes(breakpoint as Breakpoint)) ?? headers[0];
  return sorting(defaultHeader.sortName as unknown as string, defaultHeader.defaultOrder || -1);
};

export {
  header, sorting, HorizontalAlignment, Header, Sorting, defaultSorting,
};
