

















import Vue from 'vue';
import inputlabel from './input-label.vue';
import inputerror from './input-error.vue';

interface State {
  previousValue: string;
}

export default Vue.extend({
  components: {
    inputlabel,
    inputerror,
  },

  props: {
    id: String,
    label: String,
    value: {
      type: String,
      required: true,
      default: '',
    },
    placeholder: String,
    error: String,
  },

  data(): State {
    return {
      previousValue: '',
    };
  },

  computed: {
    proxyValue: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        if (!value || /^[+][0-9]*$/.test(value)) {
          this.$emit('update-phone', value);
        } else {
          (this.$refs.inputPhone as HTMLInputElement).value = this.previousValue;
          this.$emit('update-phone', this.previousValue);
        }
        this.$emit('update:error', '');
      },
    },
  },

  methods: {
    onKeyDown(event: { target: { value: string; }; }) {
      this.previousValue = event.target.value;
    },
    filterInputs(event: { charCode: number; key: string; preventDefault: () => void; }) {
      if (!((event.charCode > 47 && event.charCode < 58) || event.key === '+')) {
        event.preventDefault();
      }
    },
  },
});
