































import Vue from 'vue';
import descriptionbox from './description-box.vue';
import managementIcon from './management.svg';
import managementArrowIcon from './management-arrow.svg';
import materialIcon from './material.svg';
import materialArrowIcon from './material-arrow.svg';
import statisticsIcon from './statistics.svg';
import statisticsArrowIcon from './statistics-arrow.svg';

export default Vue.extend({
  components: {
    descriptionbox,
    managementIcon,
    managementArrowIcon,
    materialIcon,
    materialArrowIcon,
    statisticsIcon,
    statisticsArrowIcon,
  },
});
