import axios from 'axios';
import { ActionContext, Module } from 'vuex';
import type { Store } from '@/main/store/store-types';
import { LicenseFeaturesModule, LocalLicenseFeature } from '@/main/store/store-types';
import { LicenseApiFactory, LicenseFeature } from '@/generated/api-client';

const api = LicenseApiFactory(undefined, '', axios);

const allFeatures: Module<LicenseFeaturesModule, Store> = {
  namespaced: true,
  state: (): LicenseFeaturesModule => ({
    features: [],
  }),
  getters: {
    getFeatures(state): Array<LocalLicenseFeature> {
      return state.features;
    },
  },
  mutations: {
    setFeatures(state, features: Array<LicenseFeature>): void {
      state.features = features.map((feat) => ({
        ...feat,
        shortName: feat.name === 'Autopilot Artificial Teeth for Full Dentures'
          ? 'Autopilot Artificial Teeth' : feat.name,
      }));
    },
  },
  actions: {
    async requestFeatures({ commit }: ActionContext<LicenseFeaturesModule, Store>): Promise<void> {
      const { data } = await api.getLicenseFeatures();
      commit('setFeatures', data);
    },
  },
};

export default allFeatures;
