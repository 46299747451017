import { render, staticRenderFns } from "./license-status.vue?vue&type=template&id=7e68a8ba&lang=pug"
import script from "./license-status.vue?vue&type=script&lang=ts"
export * from "./license-status.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports