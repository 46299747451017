const toastOptions = {
  position: 'bottom-center',
  timeout: 3000,
  transition: 'fade',
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  showCloseButtonOnHover: false,
  closeButtonClassName: 'close-btn',
  bodyClassName: 'toast-body',
  closeButton: 'button',
  icon: false,
  rtl: false,
  maxToasts: 2,
};

export default toastOptions;
