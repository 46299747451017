











import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#C7C7CC',
    },
    size: {
      type: String,
      default: '18',
    },
  },
});
