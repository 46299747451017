







import Vue from 'vue';
import inputlabel from '../input/input-label.vue';

export default Vue.extend({
  components: {
    inputlabel,
  },
  props: {
    id: String,
    label: String,
    value: String,
  },
});
