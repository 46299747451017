import Vue from 'vue';
import { pageHeader } from '@/components';
import logo from '@/components/logo/logo.svg';

export default Vue.extend({
  name: 'header',
  components: {
    logo,
    pageHeader,
  },
});
