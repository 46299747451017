










import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    bgClass() {
      return this.value ? 'et-switch-on' : 'et-switch-off';
    },
    pointerStyle() {
      return { transform: this.value ? 'translateX(28px)' : 'translateX(0)' };
    },
  },
  methods: {
    toggle() {
      // @ts-ignore
      this.$emit('input', !this.value);
    },
  },
});
