import { DateTime } from 'luxon';
import { AxiosErrorHandlerBuilder, AxiosErrorHandler } from './axios-error-handler-builder';
import createVueErrorHandler from './create-vue-error-handler';
import createPlainAxiosErrorHandler from './create-plain-axios-error-handler';
import groupBySize from './group-by-size';
import { PrinterType } from '@/generated/api-client';

type typeByPrinter = 'Envision One' | 'Envision One HT' | 'P4K Advantage' | 'P4K2.0' | 'D4K Pro'
  | 'Xtreme 8K' | 'Einstein' | 'Einstein Pro' | 'Einstein Pro XL' | 'Other';

const formatMinutes = (minutes: number): string => {
  const remainder = minutes % 60;

  if ((minutes - remainder) <= 0) {
    return `${remainder} min`;
  }

  if (remainder === 0) {
    return `${minutes / 60} h`;
  }

  return `${(minutes - remainder) / 60} h ${remainder} min`;
};

const formatDate = (date: number | null | undefined) => (
  date === null || date === undefined ? '' : DateTime.fromMillis(date).toFormat('dd MMM yyyy')
);

const formatTime = (date: number | null | undefined) => (
  date === null || date === undefined ? '' : DateTime.fromMillis(date).toFormat('HH:mm')
);

const printerType = (type: typeByPrinter): PrinterType => {
  const mapping: Record<typeByPrinter, PrinterType> = {
    'Envision One': PrinterType.Eone,
    'Envision One HT': PrinterType.Eoneht,
    'P4K Advantage': PrinterType.P4kadvantage,
    'P4K2.0': PrinterType.P4k20,
    'D4K Pro': PrinterType.D4kpro,
    'Xtreme 8K': PrinterType.Xtreme,
    Einstein: PrinterType.Einstein,
    'Einstein Pro': PrinterType.Einsteinpro,
    'Einstein Pro XL': PrinterType.Einsteinproxl,
    Other: PrinterType.Other,
  };

  return mapping[type] || PrinterType.Other;
};

const firstCharToUpperCase = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

// eslint-disable-next-line
export {
  AxiosErrorHandlerBuilder, AxiosErrorHandler, createVueErrorHandler, createPlainAxiosErrorHandler,
  formatMinutes, formatDate, formatTime, printerType, firstCharToUpperCase, groupBySize,
};
