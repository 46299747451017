import axios from 'axios';
import { ActionContext, Module } from 'vuex';
import type { Store } from '@/main/store/store-types';
import { PricingPlanListModule } from '@/main/store/store-types';
import { LicenseApiFactory, LicensePricingPlan } from '@/generated/api-client';

const api = LicenseApiFactory(undefined, '', axios);

const licensePlans: Module<PricingPlanListModule, Store> = {
  namespaced: true,
  state: (): PricingPlanListModule => ({
    planList: [],
  }),
  getters: {
    getPlanList(state): Array<LicensePricingPlan> {
      return state.planList;
    },
  },
  mutations: {
    setPlanList(state, planList: Array<LicensePricingPlan>): void {
      state.planList = planList;
    },
  },
  actions: {
    async requestPlanList({ commit }: ActionContext<PricingPlanListModule, Store>): Promise<void> {
      const { data } = await api.getLicensePricingPlans();
      commit('setPlanList', data);
    },
  },
};
export default licensePlans;
