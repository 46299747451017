import joi from 'joi';
import Vue, { PropType } from 'vue';
import {
  ArrowDown, ArrowLeft, ArrowRight, FirstPage, LastPage,
} from '@/components/icon/icons';

interface State {
  currentPage: number;
  itemsOnPage: number;
}

const range = (start: number, length: number) => new Array(length).fill(0).map((v, i) => (i + start));

export default Vue.extend({
  components: {
    FirstPage,
    LastPage,
    ArrowLeft,
    ArrowRight,
    ArrowDown,
  },

  props: {
    totalCount: {
      type: Number,
      required: true,
      validator: (value: number) => value > 0 && Number.isInteger(value),
    },
    itemsOnPageOptions: {
      type: Array as PropType<Array<number>>,
      required: true,
      validator: (value) => Array.isArray(value) && value.length > 0 && value.every((i) => Number.isInteger(i)),
    },
    // hash derived from those query parameters which affect total count of returned items
    filtersHash: {
      type: String,
      required: false,
      validator: (value) => !joi.isError(joi.string().hex().validate(value).error),
    },
  },

  data(): State {
    return {
      currentPage: 1,
      itemsOnPage: this.itemsOnPageOptions[0],
    };
  },

  computed: {
    selectedRange(): string {
      const rangeStart = ((this.currentPage - 1) * this.itemsOnPage) + 1;
      const rangeEnd = this.currentPage * this.itemsOnPage;
      return `${rangeStart}-${rangeEnd > this.totalCount ? this.totalCount : rangeEnd}`;
    },
    numberOfPages(): number {
      const itemsOnLastPage = this.totalCount % this.itemsOnPage;
      const numberOfPages = Math.floor(this.totalCount / this.itemsOnPage);
      return itemsOnLastPage > 0 ? numberOfPages + 1 : numberOfPages;
    },
    pagesRange(): Array<number> {
      const itemsOnLastPage = this.totalCount % this.itemsOnPage;
      const numberOfPages = Math.floor(this.totalCount / this.itemsOnPage);
      const totalPagesCount = itemsOnLastPage > 0 ? numberOfPages + 1 : numberOfPages;

      const rest = this.currentPage % 4;
      const group = Math.floor(this.currentPage / 4) - (rest === 0 ? 1 : 0);

      const currentRange = range(group === 0 ? 1 : (4 * group) + 1, 4);
      if (currentRange[currentRange.length - 1] > totalPagesCount) {
        return (totalPagesCount <= 4) ? range(1, totalPagesCount) : range(totalPagesCount - 3, 4);
      }

      return currentRange;
    },
  },

  methods: {
    nextPage(): void {
      this.page(this.currentPage + 1);
    },
    previousPage(): void {
      this.page(this.currentPage - 1);
    },
    page(index: number): void {
      this.currentPage = index;
      this.$emit('page-changed', {
        skip: (index - 1) * this.itemsOnPage,
        limit: this.itemsOnPage,
      });
    },
    atPage(index: number): boolean {
      return this.currentPage === index;
    },
    setItemsOnPageCount(count: number): void {
      this.itemsOnPage = count;
      this.page(1);
    },
    setCurrentPage(page: number): void {
      this.currentPage = page;
    },
  },

  watch: {
    // TODO: replace totalCount & filtersHash props with new prop ({ totalCount: number; skip: number })
    // so that currentPage here could be defined when data refresh was triggered in parent component
    filtersHash: function f() {
      this.currentPage = 1;
    },
  },
});
