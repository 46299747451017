import Vue from 'vue';
import { AppError } from '@/exceptions';

const signInWithGoogle = (auth2: gapi.auth2.GoogleAuth) => new Promise<gapi.auth2.GoogleUser>((resolve, reject) => {
  try {
    auth2.signIn({ scope: 'email' }).then((user: gapi.auth2.GoogleUser) => resolve(user));
  } catch (e) {
    reject(e);
  }
});

export default Vue.extend({
  mounted(): void {
    (this.$el as HTMLInputElement).disabled = true;

    const src = 'https://apis.google.com/js/platform.js';
    const script = document.createElement('script');
    script.onload = () => {
      try {
        gapi.load('auth2', () => gapi.auth2.init({
          client_id: '617355398373-mi98vlonbs069gc9hsqfg2vah3ib769g.apps.googleusercontent.com',
        }).then(() => { (this.$el as HTMLInputElement).disabled = false; }));
      } catch (e) {
        console.log(`Exception is occurred during Google API loading: ${JSON.stringify(e)}`);
        this.$nextTick(() => {
          throw new AppError('Exception is occurred during Google API loading.');
        });
      }
    };
    script.async = true;
    script.setAttribute('src', src);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'gAuthScript');
    document.head.appendChild(script);
  },
  methods: {
    async authenticate(): Promise<void> {
      try {
        const auth2 = gapi.auth2.getAuthInstance();
        const googleUser = await signInWithGoogle(auth2);
        this.$emit('completed', {
          provider: 'google', state: 'something', token: googleUser.getAuthResponse().id_token,
        });
      } catch (e) {
        console.log(`Exception is occurred during authentication via Google: ${e}.`);
        this.$nextTick(() => {
          throw new AppError('Exception is occurred during authentication via Google.');
        });
      }
    },
  },
});
