








import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#AEAEB2',
    },
    size: {
      type: String,
      default: '16',
    },
  },
});
