




















import Vue from 'vue';
import { EmailSuccess, EmailFailed } from './icon/icons';
import notFound from './not-found/not-found.vue';

interface State {
  status: 'not-processed' | 'confirmed' | 'expired' | 'unknown';
  buttonLabel: string;
}

export default Vue.extend({
  components: {
    notFound,
    EmailSuccess,
    EmailFailed,
  },

  props: {
    navigateTo: {
      type: String,
      required: true,
    },
    confirm: {
      type: Function,
      required: true,
    },
    checkAccount: {
      type: Function,
      required: true,
    },
  },

  data(): State {
    return {
      status: 'not-processed',
      buttonLabel: '',
    };
  },

  async mounted() {
    try {
      await this.checkAccount();
      this.buttonLabel = 'Go to Homepage';
    } catch (e) {
      this.buttonLabel = 'Go To Log In';
    }

    const urlParams = new URLSearchParams(window.location.search);
    const confirmationCode = urlParams.get('code');

    if (!confirmationCode) {
      this.status = 'unknown';
      return;
    }

    try {
      await this.confirm(confirmationCode);
      this.status = 'confirmed';
    } catch (e) {
      this.status = e.response.data.code === 21 ? 'expired' : 'unknown';
      console.log(`Oops: ${e}`);
    }
  },
  methods: {
    navigate() {
      window.location.href = `${window.location.origin}${this.navigateTo}`;
    },
  },
});
