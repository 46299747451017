/* tslint:disable */
/* eslint-disable */
/**
 * Back-end services definition
 * This document describes set of back-end services for Live Cloud application.
 *
 * The version of the OpenAPI document: 0.0.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountData
 */
export interface AccountData {
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountData
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<BusinessUnit>}
     * @memberof AccountData
     */
    'businessUnits': Array<BusinessUnit>;
    /**
     * 
     * @type {Avatar}
     * @memberof AccountData
     */
    'avatar': Avatar;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    'notifyStart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    'notifyFinish': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    'notifyError': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    'notifyAbort': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    'notifyPause': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountData
     */
    'notifyResume': boolean;
}
/**
 * 
 * @export
 * @interface AccountNotificationUpdate
 */
export interface AccountNotificationUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotificationUpdate
     */
    'notifyStart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotificationUpdate
     */
    'notifyFinish': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotificationUpdate
     */
    'notifyError': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotificationUpdate
     */
    'notifyAbort': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotificationUpdate
     */
    'notifyPause': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotificationUpdate
     */
    'notifyResume': boolean;
}
/**
 * 
 * @export
 * @interface Avatar
 */
export interface Avatar {
    /**
     * 
     * @type {number}
     * @memberof Avatar
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    'content': string;
    /**
     * 
     * @type {AvatarType}
     * @memberof Avatar
     */
    'type': AvatarType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AvatarType {
    Svg = 'svg',
    Image = 'image'
}

/**
 * 
 * @export
 * @interface BusinessUnit
 */
export interface BusinessUnit {
    /**
     * 
     * @type {string}
     * @memberof BusinessUnit
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUnit
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'materialName': string;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'materialVolume': number;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'estimatedTimeOfPrinting': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum JobSortingColumn {
    Name = 'name',
    MaterialName = 'materialName',
    MaterialVolume = 'materialVolume',
    EstimatedTimeOfPrinting = 'estimatedTimeOfPrinting'
}

/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'software': string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'deviceId': string;
    /**
     * 
     * @type {LicenseType}
     * @memberof License
     */
    'type': LicenseType;
    /**
     * 
     * @type {LicenseStatus}
     * @memberof License
     */
    'status': LicenseStatus;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'requestDate': number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'expiryDate': number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'activationDate': number | null;
}
/**
 * 
 * @export
 * @interface LicenseCreation
 */
export interface LicenseCreation {
    /**
     * 
     * @type {number}
     * @memberof LicenseCreation
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseCreation
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface LicenseFeature
 */
export interface LicenseFeature {
    /**
     * 
     * @type {number}
     * @memberof LicenseFeature
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseFeature
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseFeature
     */
    'advancedAINesting': boolean;
}
/**
 * 
 * @export
 * @interface LicensePaymentInitialization
 */
export interface LicensePaymentInitialization {
    /**
     * 
     * @type {number}
     * @memberof LicensePaymentInitialization
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof LicensePaymentInitialization
     */
    'purchaseCode': string;
}
/**
 * 
 * @export
 * @interface LicensePricingPlan
 */
export interface LicensePricingPlan {
    /**
     * 
     * @type {number}
     * @memberof LicensePricingPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicensePricingPlan
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LicensePricingPlan
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LicensePricingPlan
     */
    'price': string;
    /**
     * License period measured in days.
     * @type {number}
     * @memberof LicensePricingPlan
     */
    'period': number;
    /**
     * 
     * @type {LicensePricingType}
     * @memberof LicensePricingPlan
     */
    'pricingType': LicensePricingType;
    /**
     * 
     * @type {Array<LicenseFeature>}
     * @memberof LicensePricingPlan
     */
    'features': Array<LicenseFeature>;
    /**
     * 
     * @type {boolean}
     * @memberof LicensePricingPlan
     */
    'mostPopular': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicensePricingType {
    Free = 'free',
    Custom = 'custom',
    Standard = 'standard'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseRequestStatus {
    Created = 'Created',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseSortingColumn {
    Software = 'software',
    Type = 'type',
    ExpiryDate = 'expiryDate',
    Status = 'status',
    RequestDate = 'requestDate'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseStatus {
    Activated = 'activated',
    Pending = 'pending',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseType {
    Basic = 'basic',
    Premium = 'premium',
    PremiumTrial = 'premium trial',
    Enterprise = 'enterprise'
}

/**
 * 
 * @export
 * @interface LogInExternal
 */
export interface LogInExternal {
    /**
     * 
     * @type {string}
     * @memberof LogInExternal
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof LogInExternal
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'date': number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'deviceId': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amount': number;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof Payment
     */
    'status': PaymentStatus;
}
/**
 * 
 * @export
 * @interface PaymentIntentData
 */
export interface PaymentIntentData {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentData
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PaymentSortingColumn {
    Id = 'id',
    Date = 'date',
    Description = 'description',
    DeviceId = 'deviceId',
    Amount = 'amount',
    Status = 'status'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum PaymentStatus {
    Completed = 'completed',
    Failed = 'failed',
    Pending = 'pending'
}

/**
 * 
 * @export
 * @interface PrinterData
 */
export interface PrinterData {
    /**
     * 
     * @type {string}
     * @memberof PrinterData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PrinterData
     */
    'name': string;
    /**
     * 
     * @type {PrinterType}
     * @memberof PrinterData
     */
    'type': PrinterType;
    /**
     * 
     * @type {PrinterStatus}
     * @memberof PrinterData
     */
    'status': PrinterStatus;
    /**
     * 
     * @type {string}
     * @memberof PrinterData
     */
    'serialNumber': string;
    /**
     * 
     * @type {Printing}
     * @memberof PrinterData
     */
    'printing'?: Printing;
    /**
     * 
     * @type {string}
     * @memberof PrinterData
     */
    'businessUnitName': string;
    /**
     * 
     * @type {PrinterDataRemovingActionType}
     * @memberof PrinterData
     */
    'removingActionType'?: PrinterDataRemovingActionType;
    /**
     * 
     * @type {boolean}
     * @memberof PrinterData
     */
    'materialActivated': boolean;
    /**
     * 
     * @type {PrinterMaterial}
     * @memberof PrinterData
     */
    'material'?: PrinterMaterial;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrinterDataRemovingActionType {
    Forbidden = 'forbidden',
    Remove = 'remove',
    Unassign = 'unassign'
}

/**
 * 
 * @export
 * @interface PrinterIdAndName
 */
export interface PrinterIdAndName {
    /**
     * 
     * @type {string}
     * @memberof PrinterIdAndName
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrinterIdAndName
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PrinterMaterial
 */
export interface PrinterMaterial {
    /**
     * 
     * @type {string}
     * @memberof PrinterMaterial
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PrinterMaterial
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface PrinterRegistrationConfirmation
 */
export interface PrinterRegistrationConfirmation {
    /**
     * 
     * @type {number}
     * @memberof PrinterRegistrationConfirmation
     */
    'confirmationCode': number;
    /**
     * 
     * @type {string}
     * @memberof PrinterRegistrationConfirmation
     */
    'businessUnitId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrinterStatus {
    Idle = 'idle',
    Offline = 'offline',
    Paused = 'paused',
    Printing = 'printing'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum PrinterType {
    Eone = 'eone',
    Eoneht = 'eoneht',
    P4kadvantage = 'p4kadvantage',
    P4k20 = 'p4k2.0',
    D4kpro = 'd4kpro',
    Xtreme = 'xtreme',
    Einstein = 'einstein',
    Einsteinpro = 'einsteinpro',
    Einsteinproxl = 'einsteinproxl',
    Proxl = 'proxl',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface PrinterUpdate
 */
export interface PrinterUpdate {
    /**
     * 
     * @type {string}
     * @memberof PrinterUpdate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Printing
 */
export interface Printing {
    /**
     * 
     * @type {string}
     * @memberof Printing
     */
    'jobName': string;
    /**
     * 
     * @type {number}
     * @memberof Printing
     */
    'numberOfLayers': number;
    /**
     * 
     * @type {number}
     * @memberof Printing
     */
    'materialVolume': number;
    /**
     * 
     * @type {number}
     * @memberof Printing
     */
    'completionRate': number;
    /**
     * 
     * @type {number}
     * @memberof Printing
     */
    'printingTimeLeft': number;
    /**
     * 
     * @type {string}
     * @memberof Printing
     */
    'preview': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrintingEvaluation {
    Successful = 'successful',
    Partially = 'partially',
    Failed = 'failed',
    NotEvaluated = 'not-evaluated'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum PrintingEventType {
    Started = 'started',
    Completed = 'completed',
    Aborted = 'aborted',
    Failed = 'failed',
    Paused = 'paused',
    Resumed = 'resumed'
}

/**
 * 
 * @export
 * @interface PrintingHistoryItem
 */
export interface PrintingHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof PrintingHistoryItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PrintingHistoryItem
     */
    'startedAt': number;
    /**
     * 
     * @type {number}
     * @memberof PrintingHistoryItem
     */
    'endedAt': number;
    /**
     * 
     * @type {number}
     * @memberof PrintingHistoryItem
     */
    'printTime': number;
    /**
     * 
     * @type {string}
     * @memberof PrintingHistoryItem
     */
    'jobName': string;
    /**
     * 
     * @type {string}
     * @memberof PrintingHistoryItem
     */
    'printerName': string;
    /**
     * 
     * @type {string}
     * @memberof PrintingHistoryItem
     */
    'materialName': string;
    /**
     * 
     * @type {number}
     * @memberof PrintingHistoryItem
     */
    'usedMaterialVolume': number;
    /**
     * 
     * @type {PrintingStatus}
     * @memberof PrintingHistoryItem
     */
    'status': PrintingStatus;
    /**
     * 
     * @type {PrintingEvaluation}
     * @memberof PrintingHistoryItem
     */
    'evaluation': PrintingEvaluation;
    /**
     * 
     * @type {boolean}
     * @memberof PrintingHistoryItem
     */
    'broken': boolean;
    /**
     * 
     * @type {PrinterType}
     * @memberof PrintingHistoryItem
     */
    'type': PrinterType;
    /**
     * 
     * @type {string}
     * @memberof PrintingHistoryItem
     */
    'businessUnitName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrintingHistorySortingColumn {
    StartedAt = 'startedAt',
    EndedAt = 'endedAt',
    PrintTime = 'printTime',
    JobName = 'jobName',
    PrinterName = 'printerName',
    MaterialName = 'materialName',
    UsedMaterialVolume = 'usedMaterialVolume',
    Status = 'status',
    BusinessUnitName = 'businessUnitName'
}

/**
 * 
 * @export
 * @interface PrintingNotification
 */
export interface PrintingNotification {
    /**
     * 
     * @type {string}
     * @memberof PrintingNotification
     */
    'id': string;
    /**
     * 
     * @type {PrintingEventType}
     * @memberof PrintingNotification
     */
    'eventType': PrintingEventType;
    /**
     * Contains internal identifier of printer
     * @type {string}
     * @memberof PrintingNotification
     */
    'printerId': string;
    /**
     * 
     * @type {string}
     * @memberof PrintingNotification
     */
    'printerName': string;
    /**
     * 
     * @type {number}
     * @memberof PrintingNotification
     */
    'timestamp': number;
}
/**
 * 
 * @export
 * @interface PrintingStatistics
 */
export interface PrintingStatistics {
    /**
     * 
     * @type {PrintsSummary}
     * @memberof PrintingStatistics
     */
    'prints': PrintsSummary;
    /**
     * 
     * @type {PrintsEvaluation}
     * @memberof PrintingStatistics
     */
    'evaluation': PrintsEvaluation;
    /**
     * 
     * @type {Array<UsedMaterial>}
     * @memberof PrintingStatistics
     */
    'material': Array<UsedMaterial>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrintingStatus {
    Completed = 'completed',
    Failed = 'failed',
    Aborted = 'aborted'
}

/**
 * 
 * @export
 * @interface PrintsEvaluation
 */
export interface PrintsEvaluation {
    /**
     * 
     * @type {number}
     * @memberof PrintsEvaluation
     */
    'successful': number;
    /**
     * 
     * @type {number}
     * @memberof PrintsEvaluation
     */
    'partially': number;
    /**
     * 
     * @type {number}
     * @memberof PrintsEvaluation
     */
    'failed': number;
    /**
     * 
     * @type {number}
     * @memberof PrintsEvaluation
     */
    'not-evaluated': number;
}
/**
 * 
 * @export
 * @interface PrintsSummary
 */
export interface PrintsSummary {
    /**
     * 
     * @type {number}
     * @memberof PrintsSummary
     */
    'completed': number;
    /**
     * 
     * @type {number}
     * @memberof PrintsSummary
     */
    'aborted': number;
    /**
     * 
     * @type {number}
     * @memberof PrintsSummary
     */
    'failed': number;
}
/**
 * Failure of request item/single request item processing
 * @export
 * @interface RequestProcessingFailure
 */
export interface RequestProcessingFailure {
    /**
     * 
     * @type {RequestProcessingFailureCode}
     * @memberof RequestProcessingFailure
     */
    'code': RequestProcessingFailureCode;
    /**
     * Explains processing failure reason
     * @type {string}
     * @memberof RequestProcessingFailure
     */
    'description': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RequestProcessingFailureCode {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_15 = 15,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_44 = 44,
    NUMBER_45 = 45,
    NUMBER_46 = 46,
    NUMBER_47 = 47,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_51 = 51,
    NUMBER_52 = 52,
    NUMBER_53 = 53,
    NUMBER_54 = 54,
    NUMBER_55 = 55,
    NUMBER_56 = 56,
    NUMBER_57 = 57,
    NUMBER_58 = 58,
    NUMBER_120 = 120,
    NUMBER_121 = 121,
    NUMBER_125 = 125
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SortingOrder {
    NUMBER_MINUS_1 = -1,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface UsedMaterial
 */
export interface UsedMaterial {
    /**
     * 
     * @type {string}
     * @memberof UsedMaterial
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UsedMaterial
     */
    'volume': number;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account data for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list with all internal avatars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAvatars: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/avatar/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment history for current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account notification configuration for current user
         * @param {AccountNotificationUpdate} accountNotificationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountNotification: async (accountNotificationUpdate: AccountNotificationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNotificationUpdate' is not null or undefined
            assertParamExists('updateAccountNotification', 'accountNotificationUpdate', accountNotificationUpdate)
            const localVarPath = `/api/account/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountNotificationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get account data for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list with all internal avatars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalAvatars(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Avatar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalAvatars(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment history for current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update account notification configuration for current user
         * @param {AccountNotificationUpdate} accountNotificationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountNotification(accountNotificationUpdate: AccountNotificationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountNotification(accountNotificationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get account data for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): AxiosPromise<AccountData> {
            return localVarFp.getAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list with all internal avatars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAvatars(options?: any): AxiosPromise<Array<Avatar>> {
            return localVarFp.getInternalAvatars(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment history for current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments(options?: any): AxiosPromise<Array<Payment>> {
            return localVarFp.getPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account notification configuration for current user
         * @param {AccountNotificationUpdate} accountNotificationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountNotification(accountNotificationUpdate: AccountNotificationUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateAccountNotification(accountNotificationUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get account data for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list with all internal avatars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getInternalAvatars(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getInternalAvatars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment history for current account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getPayments(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account notification configuration for current user
     * @param {AccountNotificationUpdate} accountNotificationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccountNotification(accountNotificationUpdate: AccountNotificationUpdate, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateAccountNotification(accountNotificationUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticate current user through external provider
         * @param {LogInExternal} logInExternal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logInExternal: async (logInExternal: LogInExternal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logInExternal' is not null or undefined
            assertParamExists('logInExternal', 'logInExternal', logInExternal)
            const localVarPath = `/api/authentication/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInExternal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove authentication for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logOut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authenticate current user through external provider
         * @param {LogInExternal} logInExternal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logInExternal(logInExternal: LogInExternal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logInExternal(logInExternal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove authentication for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logOut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logOut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Authenticate current user through external provider
         * @param {LogInExternal} logInExternal 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logInExternal(logInExternal: LogInExternal, options?: any): AxiosPromise<void> {
            return localVarFp.logInExternal(logInExternal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove authentication for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logOut(options?: any): AxiosPromise<void> {
            return localVarFp.logOut(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Authenticate current user through external provider
     * @param {LogInExternal} logInExternal 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logInExternal(logInExternal: LogInExternal, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logInExternal(logInExternal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove authentication for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logOut(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logOut(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenseApi - axios parameter creator
 * @export
 */
export const LicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Start license payment flow
         * @param {LicensePaymentInitialization} licensePaymentInitialization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicensePayment: async (licensePaymentInitialization: LicensePaymentInitialization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePaymentInitialization' is not null or undefined
            assertParamExists('createLicensePayment', 'licensePaymentInitialization', licensePaymentInitialization)
            const localVarPath = `/api/license/payment/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licensePaymentInitialization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available license features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/license/feature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of license pricing plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensePricingPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/license/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start license purchase for current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startLicensePurchase: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startLicensePurchase', 'id', id)
            const localVarPath = `/api/license/purchase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes incoming events from Stripe services
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicensePayment: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateLicensePayment', 'body', body)
            const localVarPath = `/api/license/payment/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseApi - functional programming interface
 * @export
 */
export const LicenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Start license payment flow
         * @param {LicensePaymentInitialization} licensePaymentInitialization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLicensePayment(licensePaymentInitialization: LicensePaymentInitialization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntentData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLicensePayment(licensePaymentInitialization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of available license features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenseFeature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of license pricing plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicensePricingPlans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicensePricingPlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicensePricingPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start license purchase for current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startLicensePurchase(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startLicensePurchase(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Processes incoming events from Stripe services
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicensePayment(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicensePayment(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenseApi - factory interface
 * @export
 */
export const LicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseApiFp(configuration)
    return {
        /**
         * 
         * @summary Start license payment flow
         * @param {LicensePaymentInitialization} licensePaymentInitialization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicensePayment(licensePaymentInitialization: LicensePaymentInitialization, options?: any): AxiosPromise<PaymentIntentData> {
            return localVarFp.createLicensePayment(licensePaymentInitialization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of available license features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseFeatures(options?: any): AxiosPromise<Array<LicenseFeature>> {
            return localVarFp.getLicenseFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of license pricing plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensePricingPlans(options?: any): AxiosPromise<Array<LicensePricingPlan>> {
            return localVarFp.getLicensePricingPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start license purchase for current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startLicensePurchase(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.startLicensePurchase(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes incoming events from Stripe services
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicensePayment(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.updateLicensePayment(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseApi - object-oriented interface
 * @export
 * @class LicenseApi
 * @extends {BaseAPI}
 */
export class LicenseApi extends BaseAPI {
    /**
     * 
     * @summary Start license payment flow
     * @param {LicensePaymentInitialization} licensePaymentInitialization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public createLicensePayment(licensePaymentInitialization: LicensePaymentInitialization, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).createLicensePayment(licensePaymentInitialization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of available license features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseFeatures(options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of license pricing plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicensePricingPlans(options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicensePricingPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start license purchase for current user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public startLicensePurchase(id: string, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).startLicensePurchase(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes incoming events from Stripe services
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public updateLicensePayment(body: object, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).updateLicensePayment(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrinterApi - axios parameter creator
 * @export
 */
export const PrinterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm printer registration for current user
         * @param {PrinterRegistrationConfirmation} printerRegistrationConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPrinterRegistration: async (printerRegistrationConfirmation: PrinterRegistrationConfirmation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'printerRegistrationConfirmation' is not null or undefined
            assertParamExists('confirmPrinterRegistration', 'printerRegistrationConfirmation', printerRegistrationConfirmation)
            const localVarPath = `/api/printer/confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printerRegistrationConfirmation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printer data for specific printer assigned to current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinter: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrinter', 'id', id)
            const localVarPath = `/api/printer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of jobs assigned with printer
         * @param {string} id 
         * @param {JobSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {string} [filter] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinterJobs: async (id: string, sortingColumn: JobSortingColumn, sortingOrder: SortingOrder, filter?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrinterJobs', 'id', id)
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('getPrinterJobs', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('getPrinterJobs', 'sortingOrder', sortingOrder)
            const localVarPath = `/api/printer/{id}/job`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get count of jobs assigned with printer
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinterJobsCount: async (id: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrinterJobsCount', 'id', id)
            const localVarPath = `/api/printer/{id}/job`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printer data for printers assigned to current user
         * @param {PrinterStatus} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinters: async (status?: PrinterStatus, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printers count for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintersCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printing history records for target printer
         * @param {string} id 
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingHistories: async (id: string, sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, skip: number, limit: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrintingHistories', 'id', id)
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('getPrintingHistories', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('getPrintingHistories', 'sortingOrder', sortingOrder)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getPrintingHistories', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getPrintingHistories', 'limit', limit)
            const localVarPath = `/api/printer/{id}/printing/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printing history records count for target printer
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingHistoriesCount: async (id: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrintingHistoriesCount', 'id', id)
            const localVarPath = `/api/printer/{id}/printing/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove specific printer assigned to current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrinter: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removePrinter', 'id', id)
            const localVarPath = `/api/printer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove printings history entities by ids
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrintingsHistory: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('removePrintingsHistory', 'ids', ids)
            const localVarPath = `/api/printing/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update printer data for specific printer assigned to current user
         * @param {string} id 
         * @param {PrinterUpdate} printerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrinter: async (id: string, printerUpdate: PrinterUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrinter', 'id', id)
            // verify required parameter 'printerUpdate' is not null or undefined
            assertParamExists('updatePrinter', 'printerUpdate', printerUpdate)
            const localVarPath = `/api/printer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(printerUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrinterApi - functional programming interface
 * @export
 */
export const PrinterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrinterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm printer registration for current user
         * @param {PrinterRegistrationConfirmation} printerRegistrationConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPrinterRegistration(printerRegistrationConfirmation: PrinterRegistrationConfirmation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrinterIdAndName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPrinterRegistration(printerRegistrationConfirmation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printer data for specific printer assigned to current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrinter(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrinterData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrinter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of jobs assigned with printer
         * @param {string} id 
         * @param {JobSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {string} [filter] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrinterJobs(id: string, sortingColumn: JobSortingColumn, sortingOrder: SortingOrder, filter?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Job>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrinterJobs(id, sortingColumn, sortingOrder, filter, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get count of jobs assigned with printer
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrinterJobsCount(id: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrinterJobsCount(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printer data for printers assigned to current user
         * @param {PrinterStatus} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrinters(status?: PrinterStatus, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrinterData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrinters(status, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printers count for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintersCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintersCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printing history records for target printer
         * @param {string} id 
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintingHistories(id: string, sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, skip: number, limit: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintingHistoryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintingHistories(id, sortingColumn, sortingOrder, skip, limit, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printing history records count for target printer
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintingHistoriesCount(id: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintingHistoriesCount(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove specific printer assigned to current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePrinter(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removePrinter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove printings history entities by ids
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePrintingsHistory(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removePrintingsHistory(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update printer data for specific printer assigned to current user
         * @param {string} id 
         * @param {PrinterUpdate} printerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrinter(id: string, printerUpdate: PrinterUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrinter(id, printerUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrinterApi - factory interface
 * @export
 */
export const PrinterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrinterApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm printer registration for current user
         * @param {PrinterRegistrationConfirmation} printerRegistrationConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPrinterRegistration(printerRegistrationConfirmation: PrinterRegistrationConfirmation, options?: any): AxiosPromise<PrinterIdAndName> {
            return localVarFp.confirmPrinterRegistration(printerRegistrationConfirmation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printer data for specific printer assigned to current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinter(id: string, options?: any): AxiosPromise<PrinterData> {
            return localVarFp.getPrinter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of jobs assigned with printer
         * @param {string} id 
         * @param {JobSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {string} [filter] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinterJobs(id: string, sortingColumn: JobSortingColumn, sortingOrder: SortingOrder, filter?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<Job>> {
            return localVarFp.getPrinterJobs(id, sortingColumn, sortingOrder, filter, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get count of jobs assigned with printer
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinterJobsCount(id: string, filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPrinterJobsCount(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printer data for printers assigned to current user
         * @param {PrinterStatus} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrinters(status?: PrinterStatus, filter?: string, options?: any): AxiosPromise<Array<PrinterData>> {
            return localVarFp.getPrinters(status, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printers count for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintersCount(options?: any): AxiosPromise<void> {
            return localVarFp.getPrintersCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printing history records for target printer
         * @param {string} id 
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingHistories(id: string, sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, skip: number, limit: number, filter?: string, options?: any): AxiosPromise<Array<PrintingHistoryItem>> {
            return localVarFp.getPrintingHistories(id, sortingColumn, sortingOrder, skip, limit, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printing history records count for target printer
         * @param {string} id 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingHistoriesCount(id: string, filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPrintingHistoriesCount(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove specific printer assigned to current user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrinter(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removePrinter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove printings history entities by ids
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrintingsHistory(ids: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.removePrintingsHistory(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update printer data for specific printer assigned to current user
         * @param {string} id 
         * @param {PrinterUpdate} printerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrinter(id: string, printerUpdate: PrinterUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updatePrinter(id, printerUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrinterApi - object-oriented interface
 * @export
 * @class PrinterApi
 * @extends {BaseAPI}
 */
export class PrinterApi extends BaseAPI {
    /**
     * 
     * @summary Confirm printer registration for current user
     * @param {PrinterRegistrationConfirmation} printerRegistrationConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public confirmPrinterRegistration(printerRegistrationConfirmation: PrinterRegistrationConfirmation, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).confirmPrinterRegistration(printerRegistrationConfirmation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printer data for specific printer assigned to current user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrinter(id: string, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrinter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of jobs assigned with printer
     * @param {string} id 
     * @param {JobSortingColumn} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {string} [filter] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrinterJobs(id: string, sortingColumn: JobSortingColumn, sortingOrder: SortingOrder, filter?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrinterJobs(id, sortingColumn, sortingOrder, filter, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get count of jobs assigned with printer
     * @param {string} id 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrinterJobsCount(id: string, filter?: string, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrinterJobsCount(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printer data for printers assigned to current user
     * @param {PrinterStatus} [status] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrinters(status?: PrinterStatus, filter?: string, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrinters(status, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printers count for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrintersCount(options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrintersCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printing history records for target printer
     * @param {string} id 
     * @param {PrintingHistorySortingColumn} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {number} skip 
     * @param {number} limit 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrintingHistories(id: string, sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, skip: number, limit: number, filter?: string, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrintingHistories(id, sortingColumn, sortingOrder, skip, limit, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printing history records count for target printer
     * @param {string} id 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public getPrintingHistoriesCount(id: string, filter?: string, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).getPrintingHistoriesCount(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove specific printer assigned to current user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public removePrinter(id: string, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).removePrinter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove printings history entities by ids
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public removePrintingsHistory(ids: Array<string>, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).removePrintingsHistory(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update printer data for specific printer assigned to current user
     * @param {string} id 
     * @param {PrinterUpdate} printerUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrinterApi
     */
    public updatePrinter(id: string, printerUpdate: PrinterUpdate, options?: AxiosRequestConfig) {
        return PrinterApiFp(this.configuration).updatePrinter(id, printerUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrintingApi - axios parameter creator
 * @export
 */
export const PrintingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download printing history for current user
         * @param {'application/pdf' | 'text/csv'} xAccept 
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {Array<string>} [ids] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPrintingHistory: async (xAccept: 'application/pdf' | 'text/csv', sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, ids?: Array<string>, from?: string, to?: string, filter?: string, timezone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAccept' is not null or undefined
            assertParamExists('downloadPrintingHistory', 'xAccept', xAccept)
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('downloadPrintingHistory', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('downloadPrintingHistory', 'sortingOrder', sortingOrder)
            const localVarPath = `/api/printing/history/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (xAccept !== undefined && xAccept !== null) {
                localVarHeaderParameter['X-Accept'] = String(xAccept);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printing notifications for current user
         * @param {number} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingNotifications: async (interval: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getPrintingNotifications', 'interval', interval)
            const localVarPath = `/api/printing/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printings history for current user
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingsHistory: async (sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, from?: string, to?: string, filter?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('getPrintingsHistory', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('getPrintingsHistory', 'sortingOrder', sortingOrder)
            const localVarPath = `/api/printing/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get printings history records count for current user
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingsHistoryCount: async (from?: string, to?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printing/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintingApi - functional programming interface
 * @export
 */
export const PrintingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download printing history for current user
         * @param {'application/pdf' | 'text/csv'} xAccept 
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {Array<string>} [ids] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPrintingHistory(xAccept: 'application/pdf' | 'text/csv', sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, ids?: Array<string>, from?: string, to?: string, filter?: string, timezone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPrintingHistory(xAccept, sortingColumn, sortingOrder, ids, from, to, filter, timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printing notifications for current user
         * @param {number} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintingNotifications(interval: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintingNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintingNotifications(interval, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printings history for current user
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintingsHistory(sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, from?: string, to?: string, filter?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintingHistoryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintingsHistory(sortingColumn, sortingOrder, from, to, filter, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get printings history records count for current user
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintingsHistoryCount(from?: string, to?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintingsHistoryCount(from, to, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrintingApi - factory interface
 * @export
 */
export const PrintingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintingApiFp(configuration)
    return {
        /**
         * 
         * @summary Download printing history for current user
         * @param {'application/pdf' | 'text/csv'} xAccept 
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {Array<string>} [ids] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPrintingHistory(xAccept: 'application/pdf' | 'text/csv', sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, ids?: Array<string>, from?: string, to?: string, filter?: string, timezone?: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadPrintingHistory(xAccept, sortingColumn, sortingOrder, ids, from, to, filter, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printing notifications for current user
         * @param {number} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingNotifications(interval: number, options?: any): AxiosPromise<Array<PrintingNotification>> {
            return localVarFp.getPrintingNotifications(interval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printings history for current user
         * @param {PrintingHistorySortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingsHistory(sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, from?: string, to?: string, filter?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PrintingHistoryItem>> {
            return localVarFp.getPrintingsHistory(sortingColumn, sortingOrder, from, to, filter, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get printings history records count for current user
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingsHistoryCount(from?: string, to?: string, filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPrintingsHistoryCount(from, to, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintingApi - object-oriented interface
 * @export
 * @class PrintingApi
 * @extends {BaseAPI}
 */
export class PrintingApi extends BaseAPI {
    /**
     * 
     * @summary Download printing history for current user
     * @param {'application/pdf' | 'text/csv'} xAccept 
     * @param {PrintingHistorySortingColumn} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {Array<string>} [ids] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [filter] 
     * @param {string} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public downloadPrintingHistory(xAccept: 'application/pdf' | 'text/csv', sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, ids?: Array<string>, from?: string, to?: string, filter?: string, timezone?: string, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).downloadPrintingHistory(xAccept, sortingColumn, sortingOrder, ids, from, to, filter, timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printing notifications for current user
     * @param {number} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public getPrintingNotifications(interval: number, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).getPrintingNotifications(interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printings history for current user
     * @param {PrintingHistorySortingColumn} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [filter] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public getPrintingsHistory(sortingColumn: PrintingHistorySortingColumn, sortingOrder: SortingOrder, from?: string, to?: string, filter?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).getPrintingsHistory(sortingColumn, sortingOrder, from, to, filter, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get printings history records count for current user
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public getPrintingsHistoryCount(from?: string, to?: string, filter?: string, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).getPrintingsHistoryCount(from, to, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get printing statistics for all printers assigned to current user
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingStatistics: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get printing statistics for all printers assigned to current user
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintingStatistics(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintingStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintingStatistics(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get printing statistics for all printers assigned to current user
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintingStatistics(from?: string, to?: string, options?: any): AxiosPromise<PrintingStatistics> {
            return localVarFp.getPrintingStatistics(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @summary Get printing statistics for all printers assigned to current user
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getPrintingStatistics(from?: string, to?: string, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getPrintingStatistics(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


