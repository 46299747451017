




import Vue from 'vue';
import VueTypes from 'vue-types';
import statusBox from './status-box.vue';
import type { StatusBox } from './status-box.vue';

interface State {
  userStatus: StatusBox;
}

export default Vue.extend({
  props: {
    status: VueTypes.string.isRequired,
  },

  components: {
    statusBox,
  },

  data(): State {
    return {
      userStatus: {
        active: {
          color: '#2CC77C',
          backgroundColor: 'rgba(44, 199, 124, 0.05)',
        },
        blocked: {
          color: '#FF3B30',
          backgroundColor: 'rgba(255, 59, 48, 0.05)',
        },
      },
    };
  },
});
