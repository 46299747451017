






import Vue, { PropType } from 'vue';

export interface StatusBox {
  [key: string]: {
    color: string;
    backgroundColor: string;
    text?: string;
  }
}

export default Vue.extend({
  props: {
    status: {
      type: String,
      required: true,
    },
    statusData: {
      type: Object as PropType<StatusBox>,
      required: true,
    },
    collapseScreens: {
      type: Array as PropType<Array<string>>,
      default: () => ['sm'],
    },
  },

  computed: {
    style(): any {
      const key = this.status.toLowerCase();
      return {
        color: this.statusData[key].color,
        borderColor: this.statusData[key].color,
        backgroundColor: this.statusData[key].backgroundColor,
      };
    },
    statusObject(): any {
      const statusText = this.status.toLowerCase();
      const text = this.statusData[statusText].text || this.status;
      return {
        first: text.charAt(0).toUpperCase(),
        last: text.slice(1),
      };
    },
    collapsed(): boolean {
      return this.collapseScreens.includes(this.$screen.breakpoint!) || this.collapseScreens.includes('all');
    },
  },
});
