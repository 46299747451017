










import Vue from 'vue';
import pageheader from '../page-header/page-header-public.vue';
import logo from '../logo/logo.svg';

export default Vue.extend({
  name: 'container',
  components: {
    // applicationFooter,
    logo,
    pageheader,
  },
});
