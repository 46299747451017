






import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#AEAEB2',
    },
    width: {
      type: String,
      default: '12',
    },
    height: {
      type: String,
      default: '18',
    },
  },
});
