








import Vue from 'vue';
import inputlabel from './input-label.vue';
import inputerror from './input-error.vue';
import inputfield from './input-field.vue';

export default Vue.extend({
  components: {
    inputlabel,
    inputerror,
    inputfield,
  },
  props: {
    id: String,
    label: String,
    value: String,
    placeholder: String,
    error: String,
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string): void {
        this.$emit('input', newValue);
        this.$emit('update:error', '');
      },
    },
  },
});
