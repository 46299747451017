











import Vue from 'vue';
import spinner from '../spinner.vue';

export default Vue.extend({
  components: {
    spinner,
  },
  props: {
    name: {
      type: String,
      required: false,
      validator: (value: string) => value != null && value.length > 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
