/* tslint:disable */
/* eslint-disable */
/**
 * Back-end admin services definition
 * This document describes set of back-end services for Live Cloud admin application.
 *
 * The version of the OpenAPI document: 0.0.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessRule
 */
export interface AccessRule {
    /**
     * 
     * @type {number}
     * @memberof AccessRule
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessRule
     */
    'ipAddress': Array<string>;
}
/**
 * 
 * @export
 * @interface AccessRuleCreationRequest
 */
export interface AccessRuleCreationRequest {
    /**
     * 
     * @type {number}
     * @memberof AccessRuleCreationRequest
     */
    'accountId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleCreationRequest
     */
    'ipAddress': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AccessRuleSortingColumn {
    Name = 'name',
    Email = 'email'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum AccountRole {
    Admin = 'admin',
    Employee = 'employee'
}

/**
 * 
 * @export
 * @interface AdminAccountUpdate
 */
export interface AdminAccountUpdate {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAccountUpdate
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAccountUpdate
     */
    'newPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAccountUpdate
     */
    'password'?: string;
    /**
     * 
     * @type {NewAvatar}
     * @memberof AdminAccountUpdate
     */
    'avatar'?: NewAvatar;
}
/**
 * 
 * @export
 * @interface Avatar
 */
export interface Avatar {
    /**
     * 
     * @type {number}
     * @memberof Avatar
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    'content': string;
    /**
     * 
     * @type {AvatarType}
     * @memberof Avatar
     */
    'type': AvatarType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AvatarType {
    Svg = 'svg',
    Image = 'image'
}

/**
 * 
 * @export
 * @interface Duration
 */
export interface Duration {
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    'years'?: number;
}
/**
 * 
 * @export
 * @interface EmailChangeConfirmation
 */
export interface EmailChangeConfirmation {
    /**
     * 
     * @type {string}
     * @memberof EmailChangeConfirmation
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'registrationDate': number;
    /**
     * 
     * @type {UserStatus}
     * @memberof Employee
     */
    'status': UserStatus;
}
/**
 * 
 * @export
 * @interface EmployeeAccountCreation
 */
export interface EmployeeAccountCreation {
    /**
     * 
     * @type {string}
     * @memberof EmployeeAccountCreation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAccountCreation
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EmployeeAccountDetails
 */
export interface EmployeeAccountDetails {
    /**
     * 
     * @type {number}
     * @memberof EmployeeAccountDetails
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAccountDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeAccountDetails
     */
    'name': string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof EmployeeAccountDetails
     */
    'status': UserStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EmployeeSortingColumn {
    Email = 'email',
    Name = 'name',
    RegistrationDate = 'registrationDate',
    Status = 'status'
}

/**
 * 
 * @export
 * @interface EmployeeUpdate
 */
export interface EmployeeUpdate {
    /**
     * 
     * @type {string}
     * @memberof EmployeeUpdate
     */
    'password'?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof EmployeeUpdate
     */
    'status'?: UserStatus;
}
/**
 * 
 * @export
 * @interface InternalAccount
 */
export interface InternalAccount {
    /**
     * 
     * @type {number}
     * @memberof InternalAccount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InternalAccount
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalAccount
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface InternalAccountData
 */
export interface InternalAccountData {
    /**
     * 
     * @type {string}
     * @memberof InternalAccountData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalAccountData
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof InternalAccountData
     */
    'emailChangeBlocked': boolean;
    /**
     * 
     * @type {Avatar}
     * @memberof InternalAccountData
     */
    'avatar': Avatar;
    /**
     * 
     * @type {AccountRole}
     * @memberof InternalAccountData
     */
    'role': AccountRole;
}
/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'software': string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'deviceId': string;
    /**
     * 
     * @type {LicenseType}
     * @memberof License
     */
    'type': LicenseType;
    /**
     * 
     * @type {LicenseStatus}
     * @memberof License
     */
    'status': LicenseStatus;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'requestDate': number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'expiryDate': number;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    'activationDate': number | null;
}
/**
 * 
 * @export
 * @interface License1
 */
export interface License1 {
    /**
     * 
     * @type {number}
     * @memberof License1
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof License1
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof License1
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof License1
     */
    'companyName': string | null;
    /**
     * 
     * @type {string}
     * @memberof License1
     */
    'tierName': string;
    /**
     * 
     * @type {LicenseStatus1}
     * @memberof License1
     */
    'status': LicenseStatus1;
    /**
     * 
     * @type {number}
     * @memberof License1
     */
    'createdAt': number;
    /**
     * 
     * @type {number}
     * @memberof License1
     */
    'expiryDate': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseAcceptablePrinterType {
    Eone = 'eone',
    P4kadvantage = 'p4kadvantage',
    D4kpro = 'd4kpro',
    Xtreme = 'xtreme',
    Einstein = 'einstein',
    Einsteinproxl = 'einsteinproxl',
    Proxl = 'proxl',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface LicenseCreationRequest
 */
export interface LicenseCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseCreationRequest
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseCreationRequest
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseCreationRequest
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseCreationRequest
     */
    'computerId': string;
    /**
     * 
     * @type {LicenseAcceptablePrinterType}
     * @memberof LicenseCreationRequest
     */
    'printerType': LicenseAcceptablePrinterType;
    /**
     * 
     * @type {string}
     * @memberof LicenseCreationRequest
     */
    'printerSerialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof LicenseCreationRequest
     */
    'tierId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LicenseCreationRequest
     */
    'featureIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseCreationRequest
     */
    'trial': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseCreationRequest
     */
    'sendEmailToCreator': boolean;
}
/**
 * 
 * @export
 * @interface LicenseDetails
 */
export interface LicenseDetails {
    /**
     * 
     * @type {number}
     * @memberof LicenseDetails
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'companyName': string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'tierName': string;
    /**
     * 
     * @type {LicenseStatus1}
     * @memberof LicenseDetails
     */
    'status': LicenseStatus1;
    /**
     * 
     * @type {number}
     * @memberof LicenseDetails
     */
    'expiryDate': number | null;
    /**
     * 
     * @type {number}
     * @memberof LicenseDetails
     */
    'createdAt': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'computerId': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'printerSerialNumber': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseDetails
     */
    'activatedFeatures': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    'creatorName': string;
}
/**
 * 
 * @export
 * @interface LicenseFeature1
 */
export interface LicenseFeature1 {
    /**
     * 
     * @type {number}
     * @memberof LicenseFeature1
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseFeature1
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseFeature1
     */
    'optional': boolean;
}
/**
 * 
 * @export
 * @interface LicenseFeature2
 */
export interface LicenseFeature2 {
    /**
     * 
     * @type {number}
     * @memberof LicenseFeature2
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseFeature2
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseFeatureInclusion {
    Yes = 'yes',
    No = 'no',
    Optional = 'optional'
}

/**
 * 
 * @export
 * @interface LicenseFeatureToLicenseTierMapping
 */
export interface LicenseFeatureToLicenseTierMapping {
    /**
     * 
     * @type {number}
     * @memberof LicenseFeatureToLicenseTierMapping
     */
    'tierId': number;
    /**
     * 
     * @type {number}
     * @memberof LicenseFeatureToLicenseTierMapping
     */
    'featureId': number;
    /**
     * 
     * @type {LicenseFeatureInclusion}
     * @memberof LicenseFeatureToLicenseTierMapping
     */
    'included': LicenseFeatureInclusion;
}
/**
 * 
 * @export
 * @interface LicenseFeaturesToLicenseTiersMapping
 */
export interface LicenseFeaturesToLicenseTiersMapping {
    /**
     * 
     * @type {Array<LicenseTier1>}
     * @memberof LicenseFeaturesToLicenseTiersMapping
     */
    'tiers': Array<LicenseTier1>;
    /**
     * 
     * @type {Array<LicenseFeature2>}
     * @memberof LicenseFeaturesToLicenseTiersMapping
     */
    'features': Array<LicenseFeature2>;
    /**
     * 
     * @type {Array<LicenseFeatureToLicenseTierMapping>}
     * @memberof LicenseFeaturesToLicenseTiersMapping
     */
    'mapping': Array<LicenseFeatureToLicenseTierMapping>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicensePricingType {
    Free = 'free',
    Custom = 'custom',
    Standard = 'standard'
}

/**
 * 
 * @export
 * @interface LicenseRequestDetails
 */
export interface LicenseRequestDetails {
    /**
     * 
     * @type {string}
     * @memberof LicenseRequestDetails
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequestDetails
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequestDetails
     */
    'computerId': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequestDetails
     */
    'serialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof LicenseRequestDetails
     */
    'tierId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LicenseRequestDetails
     */
    'extraFeatures'?: Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseSortingColumn {
    Software = 'software',
    Type = 'type',
    ExpiryDate = 'expiryDate',
    Status = 'status',
    RequestDate = 'requestDate'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseSortingColumn1 {
    CustomerName = 'customerName',
    CustomerEmail = 'customerEmail',
    CompanyName = 'companyName',
    TierName = 'tierName',
    CreatedAt = 'createdAt',
    ExpiryDate = 'expiryDate',
    Status = 'status'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseStatus {
    Activated = 'activated',
    Pending = 'pending',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseStatus1 {
    Active = 'active',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @interface LicenseTier
 */
export interface LicenseTier {
    /**
     * 
     * @type {number}
     * @memberof LicenseTier
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseTier
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseTier
     */
    'configurable': boolean;
    /**
     * 
     * @type {Array<LicenseFeature1>}
     * @memberof LicenseTier
     */
    'features': Array<LicenseFeature1>;
    /**
     * 
     * @type {LicenseTierStatus}
     * @memberof LicenseTier
     */
    'status': LicenseTierStatus;
    /**
     * 
     * @type {LicenseTierLicenseLifetime}
     * @memberof LicenseTier
     */
    'licenseLifetime': LicenseTierLicenseLifetime;
}
/**
 * 
 * @export
 * @interface LicenseTier1
 */
export interface LicenseTier1 {
    /**
     * 
     * @type {number}
     * @memberof LicenseTier1
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseTier1
     */
    'name': string;
    /**
     * 
     * @type {LicenseTierStatus}
     * @memberof LicenseTier1
     */
    'status': LicenseTierStatus;
}
/**
 * 
 * @export
 * @interface LicenseTierDetails
 */
export interface LicenseTierDetails {
    /**
     * 
     * @type {number}
     * @memberof LicenseTierDetails
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseTierDetails
     */
    'name': string;
    /**
     * 
     * @type {LicenseTierStatus}
     * @memberof LicenseTierDetails
     */
    'status': LicenseTierStatus;
    /**
     * Number of seconds since epoch
     * @type {number}
     * @memberof LicenseTierDetails
     */
    'dateOfLastModification': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseTierDetails
     */
    'modifiedBy': string;
    /**
     * 
     * @type {number}
     * @memberof LicenseTierDetails
     */
    'numberOfActiveLicenses': number;
    /**
     * 
     * @type {number}
     * @memberof LicenseTierDetails
     */
    'totalNumberOfLicenses': number;
    /**
     * 
     * @type {Array<LicenseTierFeatureMapping>}
     * @memberof LicenseTierDetails
     */
    'featuresMapping': Array<LicenseTierFeatureMapping>;
}
/**
 * 
 * @export
 * @interface LicenseTierFeatureMapping
 */
export interface LicenseTierFeatureMapping {
    /**
     * 
     * @type {number}
     * @memberof LicenseTierFeatureMapping
     */
    'featureId': number;
    /**
     * 
     * @type {string}
     * @memberof LicenseTierFeatureMapping
     */
    'featureName': string;
    /**
     * 
     * @type {LicenseFeatureInclusion}
     * @memberof LicenseTierFeatureMapping
     */
    'included': LicenseFeatureInclusion;
}
/**
 * 
 * @export
 * @interface LicenseTierLicenseFeatureMappingUpdate
 */
export interface LicenseTierLicenseFeatureMappingUpdate {
    /**
     * 
     * @type {number}
     * @memberof LicenseTierLicenseFeatureMappingUpdate
     */
    'featureId': number;
    /**
     * 
     * @type {LicenseFeatureInclusion}
     * @memberof LicenseTierLicenseFeatureMappingUpdate
     */
    'included': LicenseFeatureInclusion;
}
/**
 * 
 * @export
 * @interface LicenseTierLicenseLifetime
 */
export interface LicenseTierLicenseLifetime {
    /**
     * 
     * @type {Duration}
     * @memberof LicenseTierLicenseLifetime
     */
    'standard': Duration;
    /**
     * 
     * @type {Duration}
     * @memberof LicenseTierLicenseLifetime
     */
    'trial': Duration;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseTierStatus {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface LicenseTierUpdate
 */
export interface LicenseTierUpdate {
    /**
     * 
     * @type {LicenseTierStatus}
     * @memberof LicenseTierUpdate
     */
    'status'?: LicenseTierStatus;
    /**
     * 
     * @type {Array<LicenseTierLicenseFeatureMappingUpdate>}
     * @memberof LicenseTierUpdate
     */
    'featuresMapping'?: Array<LicenseTierLicenseFeatureMappingUpdate>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LicenseType {
    Basic = 'basic',
    Premium = 'premium',
    PremiumTrial = 'premium trial',
    Enterprise = 'enterprise'
}

/**
 * 
 * @export
 * @interface LicenseUpdateRequest
 */
export interface LicenseUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseUpdateRequest
     */
    'customerEmail': string;
}
/**
 * 
 * @export
 * @interface LogIn
 */
export interface LogIn {
    /**
     * 
     * @type {string}
     * @memberof LogIn
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LogIn
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof LogIn
     */
    'rememberMe': boolean;
}
/**
 * 
 * @export
 * @interface NewAvatar
 */
export interface NewAvatar {
    /**
     * 
     * @type {number}
     * @memberof NewAvatar
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAvatar
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PrinterIdPrefix {
    EtE1 = 'ET.E1',
    DhEin = 'DH.EIN',
    DhEinpro = 'DH.EINPRO',
    DhEinproxl = 'DH.EINPROXL',
    EtD4K = 'ET.D4K',
    EtXtremeDlp = 'ET.XtremeDLP',
    Xt85 = 'XT85',
    EtP4KAdvantage = 'ET.P4KAdvantage',
    EtP4K2 = 'ET.P4K2',
    Other = 'Other'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum PrinterIdSuffix {
    CDlm = 'cDLM',
    CDlmK = 'cDLM-K',
    Bm = '-BM',
    Sm = '-SM',
    R = '-R'
}

/**
 * Failure of request item/single request item processing
 * @export
 * @interface RequestProcessingFailure
 */
export interface RequestProcessingFailure {
    /**
     * 
     * @type {RequestProcessingFailureCode}
     * @memberof RequestProcessingFailure
     */
    'code': RequestProcessingFailureCode;
    /**
     * Explains processing failure reason
     * @type {string}
     * @memberof RequestProcessingFailure
     */
    'description': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RequestProcessingFailureCode {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_15 = 15,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_44 = 44,
    NUMBER_45 = 45,
    NUMBER_46 = 46,
    NUMBER_47 = 47,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_51 = 51,
    NUMBER_52 = 52,
    NUMBER_53 = 53,
    NUMBER_54 = 54,
    NUMBER_55 = 55,
    NUMBER_56 = 56,
    NUMBER_57 = 57,
    NUMBER_58 = 58,
    NUMBER_120 = 120,
    NUMBER_121 = 121,
    NUMBER_125 = 125
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SortingOrder {
    NUMBER_MINUS_1 = -1,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserStatus {
    Active = 'active',
    Blocked = 'blocked'
}


/**
 * AccessApi - axios parameter creator
 * @export
 */
export const AccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get access permission status
         * @param {string} xOriginalIP 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessPermission: async (xOriginalIP: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xOriginalIP' is not null or undefined
            assertParamExists('checkAccessPermission', 'xOriginalIP', xOriginalIP)
            const localVarPath = `/api/admin/access/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xOriginalIP !== undefined && xOriginalIP !== null) {
                localVarHeaderParameter['X-Original-IP'] = String(xOriginalIP);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new access rule
         * @param {AccessRuleCreationRequest} accessRuleCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRule: async (accessRuleCreationRequest: AccessRuleCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleCreationRequest' is not null or undefined
            assertParamExists('createAccessRule', 'accessRuleCreationRequest', accessRuleCreationRequest)
            const localVarPath = `/api/admin/access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of existing access rules
         * @param {AccessRuleSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRules: async (sortingColumn: AccessRuleSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('getAccessRules', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('getAccessRules', 'sortingOrder', sortingOrder)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getAccessRules', 'limit', limit)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getAccessRules', 'skip', skip)
            const localVarPath = `/api/admin/access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get access rules count
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRulesCount: async (filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of all \'Internal account\' entities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/access/internal-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessApi - functional programming interface
 * @export
 */
export const AccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get access permission status
         * @param {string} xOriginalIP 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAccessPermission(xOriginalIP: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAccessPermission(xOriginalIP, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new access rule
         * @param {AccessRuleCreationRequest} accessRuleCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessRule(accessRuleCreationRequest: AccessRuleCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessRule(accessRuleCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of existing access rules
         * @param {AccessRuleSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessRules(sortingColumn: AccessRuleSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessRule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessRules(sortingColumn, sortingOrder, limit, skip, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get access rules count
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessRulesCount(filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessRulesCount(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of all \'Internal account\' entities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InternalAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessApi - factory interface
 * @export
 */
export const AccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessApiFp(configuration)
    return {
        /**
         * 
         * @summary Get access permission status
         * @param {string} xOriginalIP 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessPermission(xOriginalIP: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkAccessPermission(xOriginalIP, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new access rule
         * @param {AccessRuleCreationRequest} accessRuleCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRule(accessRuleCreationRequest: AccessRuleCreationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createAccessRule(accessRuleCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of existing access rules
         * @param {AccessRuleSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRules(sortingColumn: AccessRuleSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options?: any): AxiosPromise<Array<AccessRule>> {
            return localVarFp.getAccessRules(sortingColumn, sortingOrder, limit, skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get access rules count
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRulesCount(filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAccessRulesCount(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of all \'Internal account\' entities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAccounts(options?: any): AxiosPromise<Array<InternalAccount>> {
            return localVarFp.getInternalAccounts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessApi - object-oriented interface
 * @export
 * @class AccessApi
 * @extends {BaseAPI}
 */
export class AccessApi extends BaseAPI {
    /**
     * 
     * @summary Get access permission status
     * @param {string} xOriginalIP 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public checkAccessPermission(xOriginalIP: string, options?: AxiosRequestConfig) {
        return AccessApiFp(this.configuration).checkAccessPermission(xOriginalIP, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new access rule
     * @param {AccessRuleCreationRequest} accessRuleCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public createAccessRule(accessRuleCreationRequest: AccessRuleCreationRequest, options?: AxiosRequestConfig) {
        return AccessApiFp(this.configuration).createAccessRule(accessRuleCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of existing access rules
     * @param {AccessRuleSortingColumn} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {number} limit 
     * @param {number} skip 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public getAccessRules(sortingColumn: AccessRuleSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options?: AxiosRequestConfig) {
        return AccessApiFp(this.configuration).getAccessRules(sortingColumn, sortingOrder, limit, skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get access rules count
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public getAccessRulesCount(filter?: string, options?: AxiosRequestConfig) {
        return AccessApiFp(this.configuration).getAccessRulesCount(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of all \'Internal account\' entities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessApi
     */
    public getInternalAccounts(options?: AxiosRequestConfig) {
        return AccessApiFp(this.configuration).getInternalAccounts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm existed internal account email change
         * @param {EmailChangeConfirmation} emailChangeConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAdminEmailChange: async (emailChangeConfirmation: EmailChangeConfirmation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailChangeConfirmation' is not null or undefined
            assertParamExists('confirmAdminEmailChange', 'emailChangeConfirmation', emailChangeConfirmation)
            const localVarPath = `/api/admin/email/confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailChangeConfirmation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account data for current admin/employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordReset: PasswordReset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordReset' is not null or undefined
            assertParamExists('resetPassword', 'passwordReset', passwordReset)
            const localVarPath = `/api/admin/account/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account data for current admin user
         * @param {AdminAccountUpdate} adminAccountUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminAccount: async (adminAccountUpdate: AdminAccountUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminAccountUpdate' is not null or undefined
            assertParamExists('updateAdminAccount', 'adminAccountUpdate', adminAccountUpdate)
            const localVarPath = `/api/admin/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm existed internal account email change
         * @param {EmailChangeConfirmation} emailChangeConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmAdminEmailChange(emailChangeConfirmation: EmailChangeConfirmation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmAdminEmailChange(emailChangeConfirmation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get account data for current admin/employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalAccountData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset password
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordReset: PasswordReset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(passwordReset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update account data for current admin user
         * @param {AdminAccountUpdate} adminAccountUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminAccount(adminAccountUpdate: AdminAccountUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminAccount(adminAccountUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm existed internal account email change
         * @param {EmailChangeConfirmation} emailChangeConfirmation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAdminEmailChange(emailChangeConfirmation: EmailChangeConfirmation, options?: any): AxiosPromise<void> {
            return localVarFp.confirmAdminEmailChange(emailChangeConfirmation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account data for current admin/employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): AxiosPromise<InternalAccountData> {
            return localVarFp.getAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(passwordReset: PasswordReset, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(passwordReset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account data for current admin user
         * @param {AdminAccountUpdate} adminAccountUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminAccount(adminAccountUpdate: AdminAccountUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateAdminAccount(adminAccountUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Confirm existed internal account email change
     * @param {EmailChangeConfirmation} emailChangeConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public confirmAdminEmailChange(emailChangeConfirmation: EmailChangeConfirmation, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).confirmAdminEmailChange(emailChangeConfirmation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account data for current admin/employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password
     * @param {PasswordReset} passwordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resetPassword(passwordReset: PasswordReset, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).resetPassword(passwordReset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account data for current admin user
     * @param {AdminAccountUpdate} adminAccountUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAdminAccount(adminAccountUpdate: AdminAccountUpdate, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateAdminAccount(adminAccountUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check admin user authentication status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAuthenticationStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate by provided credentials
         * @param {LogIn} logIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logIn: async (logIn: LogIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logIn' is not null or undefined
            assertParamExists('logIn', 'logIn', logIn)
            const localVarPath = `/api/admin/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove authentication for admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logOutAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check admin user authentication status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminAuthenticationStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminAuthenticationStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authenticate by provided credentials
         * @param {LogIn} logIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logIn(logIn: LogIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logIn(logIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove authentication for admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logOutAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logOutAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Check admin user authentication status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminAuthenticationStatus(options?: any): AxiosPromise<void> {
            return localVarFp.getAdminAuthenticationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate by provided credentials
         * @param {LogIn} logIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logIn(logIn: LogIn, options?: any): AxiosPromise<void> {
            return localVarFp.logIn(logIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove authentication for admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logOutAdmin(options?: any): AxiosPromise<void> {
            return localVarFp.logOutAdmin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Check admin user authentication status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getAdminAuthenticationStatus(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getAdminAuthenticationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate by provided credentials
     * @param {LogIn} logIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logIn(logIn: LogIn, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logIn(logIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove authentication for admin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logOutAdmin(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logOutAdmin(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create account for a new employee
         * @param {EmployeeAccountCreation} employeeAccountCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee: async (employeeAccountCreation: EmployeeAccountCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeAccountCreation' is not null or undefined
            assertParamExists('createEmployee', 'employeeAccountCreation', employeeAccountCreation)
            const localVarPath = `/api/admin/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeAccountCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account details for particular employee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmployeeDetails', 'id', id)
            const localVarPath = `/api/admin/employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of existing employees
         * @param {EmployeeSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployees: async (sortingColumn: EmployeeSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('getEmployees', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('getEmployees', 'sortingOrder', sortingOrder)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getEmployees', 'limit', limit)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getEmployees', 'skip', skip)
            const localVarPath = `/api/admin/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get employees count
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesCount: async (filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove account data for particular employee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployee: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEmployee', 'id', id)
            const localVarPath = `/api/admin/employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate employee\'s account password reset
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEmployeePassword: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetEmployeePassword', 'id', id)
            const localVarPath = `/api/admin/employee/{id}/password/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account data for particular employee
         * @param {number} id 
         * @param {EmployeeUpdate} employeeUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (id: number, employeeUpdate: EmployeeUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployee', 'id', id)
            // verify required parameter 'employeeUpdate' is not null or undefined
            assertParamExists('updateEmployee', 'employeeUpdate', employeeUpdate)
            const localVarPath = `/api/admin/employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create account for a new employee
         * @param {EmployeeAccountCreation} employeeAccountCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployee(employeeAccountCreation: EmployeeAccountCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployee(employeeAccountCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get account details for particular employee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeAccountDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of existing employees
         * @param {EmployeeSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployees(sortingColumn: EmployeeSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Employee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployees(sortingColumn, sortingOrder, limit, skip, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get employees count
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeesCount(filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeesCount(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove account data for particular employee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEmployee(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEmployee(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initiate employee\'s account password reset
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetEmployeePassword(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetEmployeePassword(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update account data for particular employee
         * @param {number} id 
         * @param {EmployeeUpdate} employeeUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(id: number, employeeUpdate: EmployeeUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(id, employeeUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create account for a new employee
         * @param {EmployeeAccountCreation} employeeAccountCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee(employeeAccountCreation: EmployeeAccountCreation, options?: any): AxiosPromise<void> {
            return localVarFp.createEmployee(employeeAccountCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account details for particular employee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeDetails(id: number, options?: any): AxiosPromise<EmployeeAccountDetails> {
            return localVarFp.getEmployeeDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of existing employees
         * @param {EmployeeSortingColumn} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployees(sortingColumn: EmployeeSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options?: any): AxiosPromise<Array<Employee>> {
            return localVarFp.getEmployees(sortingColumn, sortingOrder, limit, skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get employees count
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesCount(filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getEmployeesCount(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove account data for particular employee
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployee(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate employee\'s account password reset
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetEmployeePassword(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.resetEmployeePassword(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account data for particular employee
         * @param {number} id 
         * @param {EmployeeUpdate} employeeUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(id: number, employeeUpdate: EmployeeUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmployee(id, employeeUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * 
     * @summary Create account for a new employee
     * @param {EmployeeAccountCreation} employeeAccountCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public createEmployee(employeeAccountCreation: EmployeeAccountCreation, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).createEmployee(employeeAccountCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account details for particular employee
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public getEmployeeDetails(id: number, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).getEmployeeDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of existing employees
     * @param {EmployeeSortingColumn} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {number} limit 
     * @param {number} skip 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public getEmployees(sortingColumn: EmployeeSortingColumn, sortingOrder: SortingOrder, limit: number, skip: number, filter?: string, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).getEmployees(sortingColumn, sortingOrder, limit, skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get employees count
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public getEmployeesCount(filter?: string, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).getEmployeesCount(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove account data for particular employee
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public removeEmployee(id: number, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).removeEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate employee\'s account password reset
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public resetEmployeePassword(id: number, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).resetEmployeePassword(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account data for particular employee
     * @param {number} id 
     * @param {EmployeeUpdate} employeeUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public updateEmployee(id: number, employeeUpdate: EmployeeUpdate, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).updateEmployee(id, employeeUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenseApi - axios parameter creator
 * @export
 */
export const LicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new license
         * @param {LicenseCreationRequest} licenseCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicense: async (licenseCreationRequest: LicenseCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseCreationRequest' is not null or undefined
            assertParamExists('createLicense', 'licenseCreationRequest', licenseCreationRequest)
            const localVarPath = `/api/admin/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for particular license
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseDetails', 'id', id)
            const localVarPath = `/api/admin/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get collection mapping between license tiers and license features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseFeaturesToLicenseTiersMapping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/license/tier/feature/mapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get license file for particular license
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseFile', 'id', id)
            const localVarPath = `/api/admin/license/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get license request details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseRequest: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseRequest', 'id', id)
            const localVarPath = `/api/admin/license/request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides details of license tier
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseTier: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLicenseTier', 'id', id)
            const localVarPath = `/api/admin/license/tier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides collection of license tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseTiers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/license/tier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get collection of licenses
         * @param {LicenseSortingColumn1} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {LicenseStatus1} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenses: async (sortingColumn: LicenseSortingColumn1, sortingOrder: SortingOrder, limit: number, skip: number, status?: LicenseStatus1, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortingColumn' is not null or undefined
            assertParamExists('getLicenses', 'sortingColumn', sortingColumn)
            // verify required parameter 'sortingOrder' is not null or undefined
            assertParamExists('getLicenses', 'sortingOrder', sortingOrder)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getLicenses', 'limit', limit)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getLicenses', 'skip', skip)
            const localVarPath = `/api/admin/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortingColumn !== undefined) {
                localVarQueryParameter['sortingColumn'] = sortingColumn;
            }

            if (sortingOrder !== undefined) {
                localVarQueryParameter['sortingOrder'] = sortingOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get licenses count
         * @param {LicenseStatus1} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensesCount: async (status?: LicenseStatus1, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send license file to a customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLicenseFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendLicenseFile', 'id', id)
            const localVarPath = `/api/admin/license/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update license
         * @param {number} id 
         * @param {LicenseUpdateRequest} licenseUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense: async (id: number, licenseUpdateRequest: LicenseUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicense', 'id', id)
            // verify required parameter 'licenseUpdateRequest' is not null or undefined
            assertParamExists('updateLicense', 'licenseUpdateRequest', licenseUpdateRequest)
            const localVarPath = `/api/admin/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update license tier details
         * @param {number} id 
         * @param {LicenseTierUpdate} licenseTierUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenseTier: async (id: number, licenseTierUpdate: LicenseTierUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicenseTier', 'id', id)
            // verify required parameter 'licenseTierUpdate' is not null or undefined
            assertParamExists('updateLicenseTier', 'licenseTierUpdate', licenseTierUpdate)
            const localVarPath = `/api/admin/license/tier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseTierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseApi - functional programming interface
 * @export
 */
export const LicenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new license
         * @param {LicenseCreationRequest} licenseCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLicense(licenseCreationRequest: LicenseCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLicense(licenseCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get data for particular license
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get collection mapping between license tiers and license features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseFeaturesToLicenseTiersMapping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseFeaturesToLicenseTiersMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseFeaturesToLicenseTiersMapping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get license file for particular license
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get license request details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseRequest(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseRequestDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Provides details of license tier
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseTier(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseTierDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseTier(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Provides collection of license tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseTiers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenseTier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseTiers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get collection of licenses
         * @param {LicenseSortingColumn1} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {LicenseStatus1} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenses(sortingColumn: LicenseSortingColumn1, sortingOrder: SortingOrder, limit: number, skip: number, status?: LicenseStatus1, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<License1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenses(sortingColumn, sortingOrder, limit, skip, status, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get licenses count
         * @param {LicenseStatus1} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicensesCount(status?: LicenseStatus1, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicensesCount(status, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send license file to a customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendLicenseFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendLicenseFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update license
         * @param {number} id 
         * @param {LicenseUpdateRequest} licenseUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicense(id: number, licenseUpdateRequest: LicenseUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicense(id, licenseUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update license tier details
         * @param {number} id 
         * @param {LicenseTierUpdate} licenseTierUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicenseTier(id: number, licenseTierUpdate: LicenseTierUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicenseTier(id, licenseTierUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenseApi - factory interface
 * @export
 */
export const LicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new license
         * @param {LicenseCreationRequest} licenseCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicense(licenseCreationRequest: LicenseCreationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createLicense(licenseCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data for particular license
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseDetails(id: number, options?: any): AxiosPromise<LicenseDetails> {
            return localVarFp.getLicenseDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get collection mapping between license tiers and license features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseFeaturesToLicenseTiersMapping(options?: any): AxiosPromise<LicenseFeaturesToLicenseTiersMapping> {
            return localVarFp.getLicenseFeaturesToLicenseTiersMapping(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get license file for particular license
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseFile(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getLicenseFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get license request details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseRequest(id: number, options?: any): AxiosPromise<LicenseRequestDetails> {
            return localVarFp.getLicenseRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides details of license tier
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseTier(id: number, options?: any): AxiosPromise<LicenseTierDetails> {
            return localVarFp.getLicenseTier(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides collection of license tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseTiers(options?: any): AxiosPromise<Array<LicenseTier>> {
            return localVarFp.getLicenseTiers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get collection of licenses
         * @param {LicenseSortingColumn1} sortingColumn 
         * @param {SortingOrder} sortingOrder 
         * @param {number} limit 
         * @param {number} skip 
         * @param {LicenseStatus1} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenses(sortingColumn: LicenseSortingColumn1, sortingOrder: SortingOrder, limit: number, skip: number, status?: LicenseStatus1, filter?: string, options?: any): AxiosPromise<Array<License1>> {
            return localVarFp.getLicenses(sortingColumn, sortingOrder, limit, skip, status, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get licenses count
         * @param {LicenseStatus1} [status] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensesCount(status?: LicenseStatus1, filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLicensesCount(status, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send license file to a customer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLicenseFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendLicenseFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update license
         * @param {number} id 
         * @param {LicenseUpdateRequest} licenseUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(id: number, licenseUpdateRequest: LicenseUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLicense(id, licenseUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update license tier details
         * @param {number} id 
         * @param {LicenseTierUpdate} licenseTierUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenseTier(id: number, licenseTierUpdate: LicenseTierUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateLicenseTier(id, licenseTierUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseApi - object-oriented interface
 * @export
 * @class LicenseApi
 * @extends {BaseAPI}
 */
export class LicenseApi extends BaseAPI {
    /**
     * 
     * @summary Create new license
     * @param {LicenseCreationRequest} licenseCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public createLicense(licenseCreationRequest: LicenseCreationRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).createLicense(licenseCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data for particular license
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseDetails(id: number, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get collection mapping between license tiers and license features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseFeaturesToLicenseTiersMapping(options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseFeaturesToLicenseTiersMapping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get license file for particular license
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseFile(id: number, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get license request details
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseRequest(id: number, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provides details of license tier
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseTier(id: number, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseTier(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provides collection of license tiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenseTiers(options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenseTiers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get collection of licenses
     * @param {LicenseSortingColumn1} sortingColumn 
     * @param {SortingOrder} sortingOrder 
     * @param {number} limit 
     * @param {number} skip 
     * @param {LicenseStatus1} [status] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicenses(sortingColumn: LicenseSortingColumn1, sortingOrder: SortingOrder, limit: number, skip: number, status?: LicenseStatus1, filter?: string, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicenses(sortingColumn, sortingOrder, limit, skip, status, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get licenses count
     * @param {LicenseStatus1} [status] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public getLicensesCount(status?: LicenseStatus1, filter?: string, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).getLicensesCount(status, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send license file to a customer
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public sendLicenseFile(id: number, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).sendLicenseFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update license
     * @param {number} id 
     * @param {LicenseUpdateRequest} licenseUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public updateLicense(id: number, licenseUpdateRequest: LicenseUpdateRequest, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).updateLicense(id, licenseUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update license tier details
     * @param {number} id 
     * @param {LicenseTierUpdate} licenseTierUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public updateLicenseTier(id: number, licenseTierUpdate: LicenseTierUpdate, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).updateLicenseTier(id, licenseTierUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RPApi - axios parameter creator
 * @export
 */
export const RPApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download all RP Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRPData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/rp/statistics/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downloads Live Build DLP application instance feedback data as a \'.zip\' file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRPFeedbackAttachment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/rp/feedback/{id}/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downloads all historical data for RP incoming requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRPHistoryData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/rp/history/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downloads all rollback survey results data for RP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRollbackSurveyResults: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/rp/rollback/survey/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RPApi - functional programming interface
 * @export
 */
export const RPApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RPApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download all RP Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRPData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRPData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Downloads Live Build DLP application instance feedback data as a \'.zip\' file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRPFeedbackAttachment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRPFeedbackAttachment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Downloads all historical data for RP incoming requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRPHistoryData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRPHistoryData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Downloads all rollback survey results data for RP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRollbackSurveyResults(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRollbackSurveyResults(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RPApi - factory interface
 * @export
 */
export const RPApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RPApiFp(configuration)
    return {
        /**
         * 
         * @summary Download all RP Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRPData(options?: any): AxiosPromise<string> {
            return localVarFp.downloadRPData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downloads Live Build DLP application instance feedback data as a \'.zip\' file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRPFeedbackAttachment(options?: any): AxiosPromise<any> {
            return localVarFp.downloadRPFeedbackAttachment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downloads all historical data for RP incoming requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRPHistoryData(options?: any): AxiosPromise<string> {
            return localVarFp.downloadRPHistoryData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downloads all rollback survey results data for RP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRollbackSurveyResults(options?: any): AxiosPromise<string> {
            return localVarFp.downloadRollbackSurveyResults(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RPApi - object-oriented interface
 * @export
 * @class RPApi
 * @extends {BaseAPI}
 */
export class RPApi extends BaseAPI {
    /**
     * 
     * @summary Download all RP Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RPApi
     */
    public downloadRPData(options?: AxiosRequestConfig) {
        return RPApiFp(this.configuration).downloadRPData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downloads Live Build DLP application instance feedback data as a \'.zip\' file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RPApi
     */
    public downloadRPFeedbackAttachment(options?: AxiosRequestConfig) {
        return RPApiFp(this.configuration).downloadRPFeedbackAttachment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downloads all historical data for RP incoming requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RPApi
     */
    public downloadRPHistoryData(options?: AxiosRequestConfig) {
        return RPApiFp(this.configuration).downloadRPHistoryData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downloads all rollback survey results data for RP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RPApi
     */
    public downloadRollbackSurveyResults(options?: AxiosRequestConfig) {
        return RPApiFp(this.configuration).downloadRollbackSurveyResults(options).then((request) => request(this.axios, this.basePath));
    }
}


