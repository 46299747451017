import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import { DateTime, Interval } from 'luxon';
import 'vue2-datepicker/index.css';
import CalendarIcon from './calendar.svg';

interface Shortcut {
  text: string;
  onClick: () => void;
}

const shortcut = (text: string, handler: () => void) => ({
  text, onClick: handler,
});

interface State {
  lang: {
    formatLocale: {
        firstDayOfWeek: number;
    };
    monthBeforeYear: boolean;
  };
  shortcuts: () => Array<Shortcut>;
  period: Interval | null;
}

interface DatePickerComponentApi extends Vue {
  closePopup():void;
}

export default Vue.extend({
  components: {
    DatePicker,
    CalendarIcon,
  },
  data(): State {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      period: this.$store.getters['historyPeriod/getPeriod'],
      shortcuts() {
        return [
          shortcut('Today', () => {
            const now = DateTime.now();
            this.period = Interval.fromDateTimes(now, now);
          }),
          shortcut('Last 7 Days', () => {
            const now = DateTime.now();
            this.period = Interval.fromDateTimes(now.minus({ days: 7 }), now);
          }),
          shortcut('Last 14 Days', () => {
            const now = DateTime.now();
            this.period = Interval.fromDateTimes(now.minus({ days: 14 }), now);
          }),
          shortcut('Last Month', () => {
            const from = DateTime.now().startOf('month').minus({ months: 1 });
            this.period = Interval.fromDateTimes(from, from.endOf('month'));
          }),
          shortcut('Last 3 Months', () => {
            const startOfMonth = DateTime.now().startOf('month');
            this.period = Interval.fromDateTimes(startOfMonth.minus({ months: 3 }), startOfMonth.minus({ days: 1 }));
          }),
          shortcut('Last Year', () => {
            const from = DateTime.now().startOf('year').minus({ years: 1 });
            this.period = Interval.fromDateTimes(from, from.endOf('year'));
          }),
          shortcut('All Time', () => { this.period = null; }),
        ];
      },
    };
  },

  computed: {
    datesRange: {
      get() {
        const state = this as State;
        return !state.period ? [] : [state.period.start.toJSDate(), state.period.end.toJSDate()];
      },
      set(newValue: Array<Date>) {
        this.period = (!newValue || newValue.length === 0) ? null : Interval.fromDateTimes(newValue[0], newValue[1]);
      },
    },
  },

  methods: {
    closePicker() {
      (this.$refs.datepicker as DatePickerComponentApi).closePopup();
      this.period = this.$store.getters['historyPeriod/getPeriod'];
    },
    setInputValue() {
      this.$store.commit('historyPeriod/setPeriod', this.period);
    },
  },
});
