import Vue, { PluginObject } from 'vue';

const install = (vue: typeof Vue) => {
  vue.prototype.$materialVolume = (volume:number) => (volume === 0 ? 0 : Number(volume).toFixed(0));
  vue.prototype.$printingDuration = (duration:number) => {
    const integerDuration = Math.round(duration);
    const hours = Math.floor(integerDuration / 3600);
    const minutes = Math.floor((integerDuration - (hours * 3600)) / 60);
    const seconds = integerDuration - (hours * 3600) - (minutes * 60);
    const output = [] as string[];
    if (hours) {
      output.push(`${hours}h`);
    }

    if (minutes) {
      output.push(`${minutes}m`);
    }

    if (seconds) {
      output.push(`${seconds}s`);
    }

    return output.join(' ');
  };
};

const plugin: PluginObject<string> = { install };

export default plugin;
