












import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
    },
  },

  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.invalidateWidth();
        this.$emit('input', newValue);
      },
    },
  },

  mounted(): void {
    this.invalidateWidth();
  },

  methods: {
    invalidateWidth(): void {
      const probe = this.$refs['size-probe'] as HTMLInputElement;
      const edit = this.$refs.editbox as HTMLInputElement;

      if (probe && edit) {
        probe.textContent = edit.value;
        edit.style.width = `${probe.offsetWidth + 26}px`;
      }
    },
    focus() {
      this.invalidateWidth(); // TODO: remove when investigation is completed
      (this.$refs.editbox as HTMLInputElement).focus();
    },
    stopEditing(): void {
      this.$emit('blur');
    },
    emitKeypress(event: string): void {
      this.$emit('keypress', event);
    },
  },
});
