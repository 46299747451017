











import Vue, { PropType } from 'vue';
import icon from '../../icon/icon.vue';

type AvatarType = 'svg' | 'image';

export default Vue.extend({
  components: {
    icon,
  },

  props: {
    type: String as PropType<AvatarType>,
    content: String,
  },

  methods: {
    openEditor() {
      this.$emit('edit');
    },
  },
});
