








import Vue from 'vue';
import { PageNotFound } from '../icon/icons';

export default Vue.extend({
  components: {
    PageNotFound,
  },

  props: {
    label: String,
    navigateTo: String,
  },

  methods: {
    goTo() {
      window.location.href = this.navigateTo;
    },
  },
});
