import axios from 'axios';
import Vue from 'vue';
import { AccountApiFactory, Avatar } from '@/generated/api-client';
import loadingSpinner from '../../loading-spinner/loading-spinner.vue';
import icon from '../../icon/icon.vue';

const api = AccountApiFactory(undefined, '', axios);

interface State {
  loading: boolean;
  avatarList: Avatar[];
  selectedAvatarIcon: string;
}

export default Vue.extend({
  components: {
    icon,
    loadingSpinner,
  },

  data(): State {
    return {
      loading: false,
      avatarList: [],
      selectedAvatarIcon: '',
    };
  },

  async mounted() {
    try {
      this.loading = true;
      const { data } = await api.getInternalAvatars();
      this.avatarList = data.sort((a1, a2) => a1.id - a2.id);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    editAvatar() {
      this.$emit('change-view', 'avatarEditor');
    },
    setAvatarIcon(id: string) {
      if (this.selectedAvatarIcon === id) {
        this.selectedAvatarIcon = '';
        this.$emit('clear-avatar');
      } else {
        this.selectedAvatarIcon = id;
        this.$emit('set-avatar', id);
      }
    },
  },
});
