import { Module } from 'vuex';
import type { PrinterModule, Store } from '@/main/store/store-types';

const printer: Module<PrinterModule, Store> = {
  namespaced: true,

  state: (): PrinterModule => ({
    id: '',
    name: '',
    printerExists: false,
  }),

  getters: {
    getPrinterExists(state): boolean {
      return state.printerExists;
    },
  },

  mutations: {
    setupPrinter(state, data): void {
      state.id = data.id;
      state.name = data.name;
    },
    setupPrinterExists(state, value) {
      state.printerExists = value;
    },
  },
};

export default printer;
