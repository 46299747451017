import Vue from 'vue';
import qs from 'qs';

declare const configuration: { nextcloudOAuthURL:string, clientID:string, redirectURL:string };

// const signInWithGoogle = (auth2: gapi.auth2.GoogleAuth) => new Promise<gapi.auth2.GoogleUser>((resolve, reject) => {
//   try {
//     auth2.signIn({ scope: 'email' }).then((user: gapi.auth2.GoogleUser) => resolve(user));
//   } catch (e) {
//     reject(e);
//   }
// });

const state = {
  loginStyle: 'popup',
  credentialToken: Math.random() * 1000000,
  isCordova: false,
};

const startNextcloudLogin = () => new Promise<string>((resolve, reject) => {
  // console.log(process.env);

  const { clientID, redirectURL, nextcloudOAuthURL } = configuration;
  const params = {
    response_type: 'code',
    scope: 'openid profile email',
    client_id: clientID,
    redirect_uri: redirectURL,
    state: btoa(JSON.stringify(state)),
    // (put here any extra params needed - e.g. for google: 'scope')
  };

  // Create a BroadcastChannel for the popup window to return the auth code
  // see: https://mdn.io/BroadcastChannel
  const oauthLoginChannel = new BroadcastChannel('oauthLoginChannel');
  oauthLoginChannel.onmessage = async (e) => {
    const code = e.data as string;
    try {
      console.log('User in LoginDialog success', code);
      resolve(code);
      // Send this code to the AccountsJS-based server
      // await accountsClient.loginWithService('oauth', { provider: 'nextcloud', code })
      // // the 'provider' is key you specify in AccountsOauth config
      // user.value = await accountsClient.getUser()
    } catch (err) {
      console.error('Failed to authenticate with received token', code, err);
      reject(err);
      // ui.error = (e as Error).message
    }
  };

  // Open popup window with OAuth provider page
  const width = 600;
  const height = 600;
  const left = window.screenX + (window.outerWidth / 2) - (width / 2);
  const top = window.screenY + (window.outerHeight / 2) - (height / 2);
  window.open(
    `${nextcloudOAuthURL}?${qs.stringify(params)}`,
    '',
    `toolbar=no, location=no, directories=no, status=no, menubar=no,
    scrollbars=no, resizable=no, copyhistory=no, width=${width},
    height=${height}, top=${top}, left=${left}`,
  );
});

export default Vue.extend({
  methods: {
    async authenticate() {
      try {
        const code = await startNextcloudLogin();
        this.$emit('completed', { provider: 'dm', token: code, state: btoa(JSON.stringify(state)) });
      } catch (e) {
        console.error(`Exception is occurred during authentication via DM Platform: ${e}.`);
        this.$nextTick(() => {
          this.$toast(this.$t('dm.platform.communication.error'));
        });
      }
    },
  },
});
