




import Vue from 'vue';
import IconSuccessful from './icons/icon-successful.vue';
import IconPartially from './icons/icon-partially.vue';
import IconFailed from './icons/icon-failed.vue';
import IconNotEvaluated from './icons/icon-not-evaluatied.vue';

const icons = {
  successful: IconSuccessful,
  partially: IconPartially,
  failed: IconFailed,
  'not-evaluated': IconNotEvaluated,
};

export default Vue.extend({
  props: {
    evaluation: {
      type: String,
      required: true,
      validator: (value) => ['successful', 'partially', 'failed', 'not-evaluated'].includes(value),
    },
  },

  data() {
    return {
      icons,
    };
  },
});
