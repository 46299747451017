import { Module } from 'vuex';
import type { RouterModule, Store } from '@/main/store/store-types';

const router: Module<RouterModule, Store> = {
  namespaced: true,

  state: (): RouterModule => ({
    loading: false,
  }),

  getters: {
    isLoading(state): boolean {
      return state.loading;
    },
  },

  mutations: {
    setLoading(state, value: boolean) {
      state.loading = value;
    },
  },
};

export default router;
