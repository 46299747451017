















import Vue from 'vue';
import joi from 'joi';

const pagesCountSchema = joi.number().required().min(1);

export default Vue.extend({
  props: {
    pagesCount: {
      type: Number,
      required: true,
      validator: (value) => !joi.isError(pagesCountSchema.validate(value).error),
    },
  },
  data() {
    return {
      activePage: 1,
    };
  },
  methods: {
    toPage(pageNumber: number) {
      this.activePage = pageNumber;
    },
  },
});
