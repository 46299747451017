import axios from 'axios';
import { AccountApiFactory, Payment } from '@/generated/api-client';

interface PaymentsSortnames extends Payment {
  invoice: string
  id: string
}
type SortName = keyof Omit<PaymentsSortnames, ''>

type RequestApiCallParams = {
  limit?: number,
  skip?: number,
  order?: number,
  sorting?: SortName,
}

type ClientState = {
  data: Payment[],
  sorting: SortName,
  order: number,
  limit: number,
  skip: number,
}

type RequestApiCallContext = {
  api: any,
  state: ClientState,
  outerState: ClientState
}

async function requestApiCall(this: RequestApiCallContext, params: RequestApiCallParams = {}) {
  Object.keys(params).forEach((param) => {
    // @ts-ignore
    this.outerState[param] = params[param];
  });

  if (this.state.data.length === 0) {
    const { data } = await this.api.getPayments();
    this.state.data = data;
  }

  console.log(123, this.outerState.data);

  return {
    data: this.outerState.data,
    count: this.state.data.length,
  };
}

function createClient() {
  const api = AccountApiFactory(undefined, '', axios);

  const stateInner = {
    data: [],
    sorting: 'date',
    order: 1,
    limit: 10,
    skip: 0,
  };

  const stateHandler = {
    get(target: any, prop: string) {
      if (prop === 'data') {
        return target[prop].sort((a:any, b:any) => {
          if (a[target.sorting] > b[target.sorting]) {
            return target.order;
          }

          return target.order * -1;
        }).slice(target.skip, target.skip + target.limit);
      }
      return target[prop];
    },
    set(target: any, prop: string, value: any) {
      if (prop === 'data') {
        return false;
      }
      target[prop] = value;
      return true;
    },
  };
  const state = new Proxy(stateInner, stateHandler);

  // @ts-ignore
  const request = requestApiCall.bind({
    api,
    // @ts-ignore
    state: stateInner,
    outerState: state,
  });

  return {
    api,
    state,
    request,
  };
}

export {
  createClient,
  Payment,
  RequestApiCallParams,
};
