import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';

export default (handler: any) => async (to: Route, from: Route, next: NavigationGuardNext) => {
  console.log('Entering into guard...');
  try {
    await handler(to, from, next);
    console.log('Guard is executed successfully.');
  } catch (e) {
    console.error(`Exception is occurred during guard execution: ${e}`);
    // @ts-ignore
    next(e);
  }
};
