























import Vue from 'vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
      validator: (value: string) => value != null && value.length > 0,
    },
    header: {
      type: String,
      required: true,
      validator: (value: string) => value != null && value.length > 0,
    },
    accept: {
      type: String,
      required: false,
      validator: (value: string) => value != null && value.length > 0,
    },
    dismiss: {
      type: String,
      required: false,
      validator: (value: string) => value != null && value.length > 0,
    },
    acceptClass: {
      type: String,
      required: false,
      default: 'btn-primary',
    },
  },

  methods: {
    clickAccept() {
      this.$emit('accept');
    },
    clickCancel() {
      this.$emit('cancel');
    },
    show() {
      // @ts-ignore
      $(this.$el).modal(this.dismiss ? undefined : {
        backdrop: 'static',
        keyboard: false,
      });
    },
    hide() {
      // @ts-ignore
      $(this.$el).modal('hide');
    },
  },
});
