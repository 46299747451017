import axios from 'axios';
import { ActionContext, Module } from 'vuex';
import type { Store } from '@/main/store/store-types';
import {
  InternalAccountData, AdminAccountUpdate, AccountApiFactory, AvatarType, AccountRole,
} from '../../../admin/generated/api-client';

const api = AccountApiFactory(undefined, '', axios);

const adminAccount: Module<InternalAccountData, Store> = {
  namespaced: true,

  state: (): InternalAccountData => ({
    name: '',
    email: '',
    emailChangeBlocked: false,
    avatar: {
      id: 0,
      content: '',
      type: AvatarType.Svg,
    },
    role: AccountRole.Employee,
  }),

  getters: {
    getAdminInfo(state): InternalAccountData {
      return state;
    },
  },

  mutations: {
    setAdminInfo(state, {
      name, email, avatar, emailChangeBlocked, role,
    }: InternalAccountData): void {
      state.name = name || '';
      state.email = email;
      state.emailChangeBlocked = emailChangeBlocked;
      state.avatar = avatar;
      state.role = role;
    },
  },

  actions: {
    async requestAdminInfo({ commit }: ActionContext<InternalAccountData, Store>): Promise<void> {
      const { data } = await api.getAccount();
      commit('setAdminInfo', data);
    },

    async updateAdminInfo(
      { dispatch }: ActionContext<InternalAccountData, Store>, dataToUpdate: AdminAccountUpdate,
    ) {
      await api.updateAdminAccount(dataToUpdate);
      await dispatch('requestAdminInfo');
    },
  },
};

export default adminAccount;
