







import Vue from 'vue';
import Icon from '../icon/icon.vue';

export default Vue.extend({
  components: {
    Icon,
  },
  props: {
    internal: {
      type: Boolean,
      default: false,
    },
    link: String,
    icon: String,
    caption: String,
  },
});
