






import Vue, { PropType } from 'vue';
import joi from 'joi';

interface State {
  selectedItem: string;
}

const itemsSchema = joi.array().min(1).items(joi.string().min(1));

export default Vue.extend({
  props: {
    items: {
      type: Array as PropType<string[]>,
      required: true,
      validator: (value) => !joi.isError(itemsSchema.validate(value).error),
    },
  },

  data(): State {
    return {
      selectedItem: this.items[0],
    };
  },

  methods: {
    select(item: string): void {
      this.selectedItem = item;
      this.$emit('tab-selected', this.items.indexOf(item));
    },
    isActiveTab(item: string): boolean {
      return this.selectedItem === item;
    },
    getElementId(item: string): string {
      return item.toLowerCase().replace(/\s+/g, '-');
    },
  },
});
