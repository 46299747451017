import { DateTime, Interval } from 'luxon';
import { Module } from 'vuex';
import type { HistoryPeriodModule, Store } from '@/main/store/store-types';

const historyPeriod: Module<HistoryPeriodModule, Store> = {
  namespaced: true,

  state: (): HistoryPeriodModule => ({
    period: Interval.fromDateTimes(DateTime.now().startOf('month'), DateTime.now()),
  }),

  getters: {
    getPeriod(state): HistoryPeriodModule['period'] {
      return state.period;
    },
  },

  mutations: {
    setPeriod(state, period: HistoryPeriodModule['period']) {
      state.period = period;
    },
  },
};

export default historyPeriod;
