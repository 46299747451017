import _Vue from 'vue';

import axios from 'axios';
import { DateTime } from 'luxon';
import { PrintingApiFactory, PrintingNotification, PrintingEventType } from '@/generated/api-client';

const api = PrintingApiFactory(undefined, '', axios);

const IS_SUPPORTED = !!('Notification' in window) && !!('serviceWorker' in navigator);

const notificationTextMap: Record<PrintingEventType, string> = {
  started: 'The print job is started.',
  completed: 'The print job is successfully completed.',
  failed: 'The print job error has occurred.',
  aborted: 'The print job is aborted.',
  paused: 'The print job is paused.',
  resumed: 'The print job is resumed.',
};

interface NotificationPluginState {
  pollingInterval: number; // ms
  latestPolling: number;
  notifications: PrintingNotification[];
  latestNotification: number;
  timeOut: number;
}

let registration: ServiceWorkerRegistration;

const state: NotificationPluginState = {
  pollingInterval: -1,
  latestPolling: -1, // seconds
  notifications: [],
  latestNotification: -1,
  timeOut: -1,
};

const getIntervalParameter = (): number => {
  if (state.latestPolling > 0) {
    // adding few seconds to take into account the request traveling time
    return Math.round(DateTime.utc().toSeconds()) - state.latestPolling + 10;
  }
  return state.pollingInterval / 1000;
};

const checkNotificationPromise = (): boolean => {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }
  return true;
};

const requestNotificationPermission = async (): Promise<boolean> => {
  let permission: NotificationPermission;
  if (checkNotificationPromise()) {
    permission = await Notification.requestPermission();
  } else {
    permission = await new Promise<NotificationPermission>((resolve): void => {
      Notification.requestPermission((value) => {
        resolve(value);
      });
    });
  }

  return permission === 'granted';
};

const requestNotifications = async () => {
  try {
    const { data } = await api.getPrintingNotifications(getIntervalParameter());
    state.notifications = data;
  } catch (e) {
    console.error(`Error has occurred during fetching notifications data: ${e}`);
  } finally {
    state.latestPolling = Math.round(DateTime.utc().toSeconds());
  }
};

const displayNotifications = (): void => {
  const notifications = state.notifications
    .filter((nt: PrintingNotification) => nt.timestamp > state.latestNotification);
  notifications.forEach((nt: PrintingNotification) => {
    const date = DateTime.fromSeconds(nt.timestamp);
    registration.showNotification(
      'Live Cloud',
      {
        body: `${notificationTextMap[nt.eventType]}\n${nt.printerName} - ${date.toFormat('HH:mm:ss')}`,
      },
    );
  });

  if (notifications.length) {
    state.latestNotification = notifications[notifications.length - 1].timestamp;
  }
};

const refreshNotifications = async (): Promise<void> => {
  await requestNotifications();
  displayNotifications();
  clearTimeout(state.timeOut);
  state.timeOut = window.setTimeout(async () => {
    await refreshNotifications();
  }, state.latestPolling > 0 ? state.pollingInterval : 0);
};

const registerServiceWorker = async (): Promise<ServiceWorkerRegistration | null> => {
  try {
    return await navigator.serviceWorker.register('/service-worker.js');
  } catch (error) {
    console.error(`Service worker registration failed: ${error}`);
  }
  return null;
};

const initialize = async () => {
  console.log('Start initializing "Application notifications" plugin...');

  registration = await registerServiceWorker() as ServiceWorkerRegistration;
  if (!registration) {
    console.log('Service registration is not provided, "Application notifications" plugin is not started.');
    return;
  }

  if (await requestNotificationPermission()) {
    console.log('Notification permission is granted for "Application notifications" plugin.');
    refreshNotifications();
  } else {
    console.log('Notification permission is not granted, "Application notifications" plugin is not started.');
    return;
  }

  console.log('"Application notifications" plugin is started.');
};

export default {
  install(Vue: typeof _Vue, options: { pollingInterval: number }) {
    console.log('Start installing "Application notifications" plugin...');
    if (!options) {
      throw new Error('"Options" must be specified!');
    }

    if (!options.pollingInterval) {
      throw new Error('pollingInterval must be specified in "options"!');
    }

    state.pollingInterval = options.pollingInterval;

    Vue.prototype.$notifications = {
      initialize: IS_SUPPORTED ? initialize : async (): Promise<void> => {
        console.error('This browser does not support notifications');
      },
    };

    console.log('"Application notifications" plugin is successfully installed.');
  },
};
