import { VueConstructor } from 'vue';

const createMatcher = (
  passwordPropertyName: keyof VueConstructor,
) => (
  value: string, vm: VueConstructor,
): boolean => value.localeCompare(<string>vm[passwordPropertyName]) === 0;

const passwordConfirmationConstraints = (passwordPropertyName: keyof VueConstructor) => ({
  matchPassword: createMatcher(passwordPropertyName),
});

export default passwordConfirmationConstraints;
