import { maxLength, minLength } from 'vuelidate/lib/validators';
import { allowedNameSymbols } from './common';

const printerNameConstraints = {

  minLength: minLength(1),
  maxLength: maxLength(32),
  allowedSymbols: allowedNameSymbols,
};

export default printerNameConstraints;
