





import Vue from 'vue';

export default Vue.extend({
  props: {
    id: String,
    value: String,
    placeholder: String,
    error: String,
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string): void {
        this.$emit('input', newValue);
      },
    },
  },
});
