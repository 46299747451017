




import Vue from 'vue';

export default Vue.extend({
  props: {
    target: String,
    label: String,
  },
});
