








import Vue from 'vue';
import noData from './no-data/no-data.vue';
import IconEnvision from './printer-icons/icons/icon-envision.vue';

export default Vue.extend({
  components: {
    noData,
    IconEnvision,
  },

  props: {
    message: {
      type: String,
      required: true,
    },
  },
});
