import Vue from 'vue';
import popup from '../popup/popup.vue';

interface Popup {
  show():void;
  hide():void;
}

export default Vue.extend({
  name: 'app',
  components: {
    popup,
  },

  mounted() {
    // @ts-ignore
    this.$root.$on('exit-confirmation', () => (this.$refs.exitNotification as unknown as Popup).show());
  },

  methods: {
    closeExitNotification() {
      (this.$refs.exitNotification as unknown as Popup).hide();
      this.$exitFromPlatform();
    },
  },
});
