import Vue from 'vue';
import axios from 'axios';
import { AppError, HandledError } from '@/exceptions';
import { AxiosErrorHandlerBuilder } from './axios-error-handler-builder';

export default (displayMessage: (m: string) => void): typeof Vue.config.errorHandler => {
  const axiosErrorHandler = new AxiosErrorHandlerBuilder()
    .default(() => {
      displayMessage('An error occurred while communicating to the server.');
    })
    .build();

  return (e: Error, vm: Vue, info: string): void => {
    if (e instanceof HandledError) {
      console.log(`Already handled, skipping: ${e.cause?.message}`);
      return;
    }

    if ((e instanceof AppError) && e.message) {
      console.error(e.message);
      displayMessage(e.message);
      return;
    }

    // fallback for any AxiosError not handled elsewhere
    if (axios.isAxiosError(e)) {
      axiosErrorHandler(e);
      return;
    }

    console.error(`Unhandled exception is caught '${e}'.`);
    displayMessage('Error occurred');
  };
};
