import Vue from 'vue';
import { navigationItem, pageFooter } from '@/components';
import { version } from '../version.json';

export default Vue.extend({
  components: {
    navigationItem,
    pageFooter,
  },
  data() {
    return {
      version,
    };
  },
});
