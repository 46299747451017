import { ActionContext, Module } from 'vuex';
import hash from 'object-hash';
import { SortingOrder } from '@/generated/api-client';
import { Sorting as BaseSorting, Paging } from '@/components';
import type {
  Store, DataTableModule, DataTableOptions, DataTableFetchResult,
} from '@/main/store/store-types';
import { RecurrentRefresh } from '@/plugins';

type Sorting = BaseSorting<string, SortingOrder>;

const hashParameters = (provide?: () => any[]) => hash(provide ? provide() : []);

// non-resettable properties (keep values on table's component destroy)
const persistingProperties: Array<keyof DataTableModule> = ['paging', 'sorting', 'trackableParametersHash'];

const stateFactory = (): DataTableModule => ({
  paging: new Paging(1),
  // sorting may depend on screen breakpoint; default value to be provided from component's 'mounted' hook
  sorting: {
    sortingColumn: '',
    sortingOrder: 1,
  },
  data: [],
  // set to -1 when data corresponding to current query parameters were never loaded and actual totalCount is unknown
  // if value is -1 at the moment when data is requested loading progress indicator to be displayed
  totalCount: -1,
  loader: () => Promise.resolve(),
  loadingInProgress: false,
  // fetch parameters to be tracked: those which affect total count of items
  trackableParametersHash: hashParameters(),
});

const createLoader = (commit: CallableFunction, fetchFunction: () => Promise<DataTableFetchResult>,
  trackParameters?: () => any[]) => async () => {
  try {
    commit('setLoadingProgress', true);
    // hash filtering parameters before request
    const paramsHash = hashParameters(trackParameters);
    commit('setFetchResult', await fetchFunction());
    // store hash on successful request
    commit('setTrackableParametersHash', paramsHash);
  } finally {
    commit('setLoadingProgress', false);
  }
};

const dataTable: Module<DataTableModule, Store> = {
  namespaced: true,

  state: stateFactory,

  getters: {
    getPaging(state): Paging {
      return state.paging;
    },
    getSorting(state): Sorting {
      return state.sorting;
    },
    getData(state): any[] {
      return state.data;
    },
    getTotalCount(state): number {
      return state.totalCount;
    },
    getTrackableParametersHash(state): string {
      return state.trackableParametersHash;
    },
    getIsProgessShown(state): boolean {
      return state.loadingInProgress && state.totalCount === -1;
    },
  },

  mutations: {
    resetPaging(state: DataTableModule) {
      state.paging = new Paging(1);
    },
    // call from beforeDestroy()
    resetTable(state: DataTableModule) {
      const newState = stateFactory();
      Object.keys(state).forEach((key) => {
        if (!persistingProperties.includes(key as keyof DataTableModule)) {
          state[key] = newState[key];
        }
      });
    },
    // call before loading (not refreshing) new data
    // avoid displaying obsolete data if loading fails
    resetData(state: DataTableModule) {
      state.data = [];
      state.totalCount = -1;
    },
    setPaging(state: DataTableModule, value: Paging) {
      state.paging = value;
    },
    setSorting(state: DataTableModule, value: Sorting) {
      state.sorting = { ...value };
    },
    setFetchResult(state: DataTableModule, value: DataTableFetchResult) {
      state.data = value.data;
      state.totalCount = value.totalCount;
    },
    setLoadingProgress(state: DataTableModule, value: boolean) {
      state.loadingInProgress = value;
    },
    setLoader(state: DataTableModule, fn: () => Promise<void>) {
      state.loader = fn;
    },
    setTrackableParametersHash(state: DataTableModule, paramsHash: string) {
      state.trackableParametersHash = paramsHash;
    },
  },

  actions: {
    async initTable({ commit, getters }: ActionContext<DataTableModule, Store>, {
      vueInstance, fetchFunction, sorting, autoRefresh, refreshInterval, trackParameters,
    }: DataTableOptions) {
      // data could have been set after destroy hook and resetTable (if request was pending at the destroy moment)
      commit('resetData');

      commit('setSorting', sorting);

      vueInstance.$once('hook:beforeDestroy', () => {
        commit('resetTable');
      });

      const loader = createLoader(commit, fetchFunction, trackParameters);

      // set default sorting only on the first mount of table
      if (getters.getSorting.sortingColumn === '') {
        commit('setSorting', sorting);
      }

      // if filtering params do not match those of latest successful query (if any), set pagination to first page
      // Example:
      // 1. N-th page was loaded when table unmount occurred
      // 2. Filtering params bound to shared component (searchPattern, period) changed while working with other table
      // 3. After return to current table reloading N-th page with actual filtering params may return []
      if (getters.getTrackableParametersHash !== hashParameters(trackParameters)) {
        commit('setPaging', new Paging(1, getters.getPaging.itemsPerPage));
      }

      if (autoRefresh === true) {
        commit('setLoader', await RecurrentRefresh(vueInstance, loader, refreshInterval));
      } else {
        await loader();
        commit('setLoader', loader);
      }
    },
    async loadTableData({ state, commit }: ActionContext<DataTableModule, Store>) {
      // clear table (avoid displaying obsolete data in case of error) and force progress indicator
      commit('resetData');
      await state.loader();
    },
    async sortingChanged({ commit, dispatch }: ActionContext<DataTableModule, Store>, event: Sorting) {
      commit('setSorting', event);
      return dispatch('loadTableData');
    },
    async pagingChanged({ commit, dispatch }: ActionContext<DataTableModule, Store>, event: Paging) {
      commit('setPaging', event);
      return dispatch('loadTableData');
    },
    async loadTableFirstPage({ getters, commit, dispatch }: ActionContext<DataTableModule, Store>) {
      commit('setPaging', new Paging(1, getters.getPaging.itemsPerPage));
      return dispatch('loadTableData');
    },
  },
};

export default dataTable;
