





import Vue from 'vue';

export default Vue.extend({
  props: {
    id: String,
    error: String,
  },
});
