import Vue from 'vue';
import VueRouter from 'vue-router';

export default {
  install(vue: typeof Vue, options: { router: VueRouter }): void {
    if (!options) {
      throw new Error('"Options" must be specified!');
    }

    const { router } = options;
    if (!router) {
      throw new Error('Router instance must be specified in "options"!');
    }

    // @ts-ignore
    router.onError((e: Error) => vue.config.errorHandler(e));
  },
};
