import Vue, { PluginObject } from 'vue';

const install = (vue: typeof Vue, location?:string) => {
  vue.prototype.$exit = () => {
    window.location.replace(location!);
  };
};

const plugin: PluginObject<string> = { install };

export default plugin;
