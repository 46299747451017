import { maxLength, minLength } from 'vuelidate/lib/validators';
import { allowedNameSymbols } from './common';

const nameConstraints = {

  minLength: minLength(1),
  maxLength: maxLength(255),
  allowedSymbols: allowedNameSymbols,
};

export default nameConstraints;
