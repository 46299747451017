



















import Vue from 'vue';
import VueTypes from 'vue-types';
import Icon from '../icon/icon.vue';

export default Vue.extend({
  components: {
    Icon,
  },
  props: {
    version: VueTypes.string.isRequired,
  },
});
