






















import Vue from 'vue';
import inputlabel from '../input/input-label.vue';
import inputerror from '../input/input-error.vue';

export default Vue.extend({
  components: {
    inputlabel,
    inputerror,
  },
  props: {
    id: String,
    label: String,
    value: String,
    error: String,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    inputValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string): void {
        this.$emit('input', newValue);
        this.$emit('update:error', '');
      },
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
});
