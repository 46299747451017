import { ActionContext, Module } from 'vuex';
import type {
  SelectAll, HistoryActionsModule, Store,
} from '@/main/store/store-types';

const historyActions: Module<HistoryActionsModule, Store> = {
  namespaced: true,

  state: (): HistoryActionsModule => ({
    enableSelection: false,
    selectableItems: [],
  }),

  getters: {
    getEnableSelection({ enableSelection }): boolean {
      return enableSelection;
    },
    getCheckedItemsIds({ selectableItems }): (string)[] {
      return selectableItems.filter((item) => item.checked).map((s) => s.id);
    },
    getSelectableItemById: ({ selectableItems }) => (id: string) => selectableItems.find((item) => item.id === id),
    getSelectAllStatus({ selectableItems }): SelectAll {
      const visibleItems = selectableItems.filter((item) => item.visible);
      const checkedItems = visibleItems.filter((item) => item.checked).length;

      if (checkedItems === 0) {
        return 'none';
      }

      return checkedItems < visibleItems.length ? 'partially' : 'all';
    },
  },

  mutations: {
    setEnableSelection(state, value: boolean): void {
      state.enableSelection = value;
      if (state.enableSelection === false) {
        state.selectableItems = [];
      }
    },
    registerSelectableItem(state, id: string): void {
      if (!state.enableSelection) {
        return;
      }
      const index = state.selectableItems.findIndex((item) => item.id === id);
      if (index === -1) {
        const item = { id, checked: false, visible: true };
        state.selectableItems = [...state.selectableItems, item];
      } else {
        state.selectableItems[index].visible = true;
      }
    },
    updateSelectableItemChecked(state, update: { id: string; checked: boolean }): void {
      const itemToUpdate = state.selectableItems.find(({ id }) => id === update.id);
      if (!itemToUpdate) {
        return;
      }
      itemToUpdate.checked = update.checked;
    },
    updateSelectableItemVisible(state, update: { id: string; visible: boolean }): void {
      const itemToUpdate = state.selectableItems.find(({ id }) => id === update.id);
      if (!itemToUpdate) {
        return;
      }
      itemToUpdate.visible = update.visible;
    },
    dropItemsCheck(state): void {
      if (!state.enableSelection) {
        return;
      }
      state.selectableItems.forEach((item) => {
        item.checked = false;
      });
    },
    toggleSelectableItems(state, selectAll: SelectAll): void {
      switch (selectAll) {
        case 'partially':
        case 'all':
          state.selectableItems.forEach((item) => {
            if (item.visible) {
              item.checked = false;
            }
          });
          break;
        default:
          state.selectableItems.forEach((item) => {
            if (item.visible) {
              item.checked = true;
            }
          });
          break;
      }
    },
  },

  actions: {
    toggleActions({ commit, getters } : ActionContext<HistoryActionsModule, Store>): void {
      commit('setEnableSelection', !getters.getEnableSelection);
    },
    // use when remove or download 'selected items'
    resetActions({ commit } : ActionContext<HistoryActionsModule, Store>): void {
      commit('setEnableSelection', false);
    },
    toggleSelectableItems({ commit, getters }): void {
      commit('toggleSelectableItems', getters.getSelectAllStatus);
    },
  },
};

export default historyActions;
