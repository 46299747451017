import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import type { Store } from '@/main/store/store-types';
import dataState from './plugins/persistedState';
import dataTable from './modules/data-table';
import account from './modules/account';
import adminAccount from './modules/adminAccount';
import printer from './modules/printer';
import payments from './modules/payments';
import router from './modules/router';
import historyPeriod from './modules/history-period';
import allFeatures from './modules/allFeatures';
import licensePlans from './modules/licensePlans';
import licensePlan from './modules/licensePlan';
import historyActions from './modules/history-actions';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const plugins = [dataState];

if (debug) {
  plugins.push(createLogger());
}

export default new Vuex.Store<Store>({
  modules: {
    licensePlans,
    allFeatures,
    account,
    adminAccount,
    printer,
    printingHistory: dataTable,
    payments,
    router,
    historyPeriod,
    licensePlan,
    users: dataTable,
    employees: dataTable,
    licenses: dataTable,
    access: dataTable,
    historyActions,
    printerJobs: dataTable,
    printerHistory: dataTable,
  },
  strict: debug,
  plugins,
});
