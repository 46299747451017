







import Vue from 'vue';
import container from '../container/container.vue';
import serverFault from './500.vue';

export default Vue.extend({
  components: {
    container,
    serverFault,
  },
});
