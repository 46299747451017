import Vue, { PluginObject } from 'vue';

declare const configuration: { clientID:string, logoutURL:string, logoutRedirectURL:string };

const install = (vue: typeof Vue) => {
  vue.prototype.$exitFromPlatform = () => {
    const { logoutURL, clientID, logoutRedirectURL } = configuration;
    window.location.replace(`${logoutURL}?post_logout_redirect_uri=${logoutRedirectURL}&client_id=${clientID}`);
  };
};

const plugin: PluginObject<string> = { install };

export default plugin;
