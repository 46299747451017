




import Vue from 'vue';
import {
  AccountCircle, Burger, Close, Edit, FAQ, FooterLogo, Plus, Support, SortArrow, PrivacyPolicy, Check, QSign,
} from './icons';

const iconsData = {
  'account-circle': AccountCircle,
  burger: Burger,
  close: Close,
  edit: Edit,
  faq: FAQ,
  'footer-logo': FooterLogo,
  plus: Plus,
  support: Support,
  'sort-arrow': SortArrow,
  'privacy-policy': PrivacyPolicy,
  check: Check,
  qSign: QSign,
};

export default Vue.extend({
  data() {
    return {
      iconsData,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
      validator: (value) => [
        'account-circle',
        'burger',
        'close',
        'edit',
        'plus',
        'faq',
        'footer-logo',
        'support',
        'sort-arrow',
        'privacy-policy',
        'check',
        'qSign',
      ].includes(value),
    },
  },
});
