import Vue from 'vue';

export default {
  install(vue: typeof Vue) {
    // errors thrown from some Promises do not reach global errorHandler (appear as 'Uncaught (in promise)').
    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      console.error(`Unhandled promise rejection: '${event.reason}'.`);
      // @ts-ignore
      vue.config.errorHandler(event.reason);
    });
  },
};
