





import Vue from 'vue';
import IconD4kpro from './icons/icon-d4kpro.vue';
import IconEnvisionOne from './icons/icon-envisionone.vue';
import IconEnvisionOneHT from './icons/icon-envisiononeht.vue';
import IconPerfactory from './icons/icon-perfactory.vue';
import IconVida from './icons/icon-vida.vue';
import IconXtreme from './icons/icon-xtreme.vue';
import IconEinstein from './icons/icon-einstein.vue';
import IconEinsteinPro from './icons/icon-einsteinpro.vue';
import IconEinsteinProXL from './icons/icon-einsteinproxl.vue';

const iconsData = {
  eone: IconEnvisionOne,
  eoneht: IconEnvisionOneHT,
  p4kadvantage: IconPerfactory,
  'p4k2.0': IconEinsteinProXL,
  d4kpro: IconD4kpro,
  xtreme: IconXtreme,
  einstein: IconEinstein,
  einsteinpro: IconEinsteinPro,
  einsteinproxl: IconEinsteinProXL,
  proxl: IconEinsteinProXL,
  other: IconVida,
};

export default Vue.extend({
  props: {
    printerType: {
      type: String,
      required: true,
      validator: (value: string) => Object.keys(iconsData).includes(value),
    },
    background: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      iconsData,
    };
  },
});
