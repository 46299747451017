import Vue, { PropType } from 'vue';
import { ArrowDown } from '@/components/icon/icons';
import inputerror from '../input/input-error.vue';

export default Vue.extend({
  components: {
    ArrowDown,
    inputerror,
  },

  props: {
    id: String,
    label: String,
    error: String,
    value: [Number, String, Object],
    selectOptions: Array as PropType<Array<number | string | unknown>>,
    mapOption: {
      type: Function,
      required: false,
      default: (option: any) => option,
    },
    hasLabelAndError: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    containerId() {
      return `${this.id}-container`;
    },
    dropdownId() {
      return `${this.id}-dropdown`;
    },
    errorId() {
      return `${this.id}-error`;
    },
  },

  methods: {
    setCurrentItem(value:any) {
      // @ts-ignore
      this.$emit('input', value);
      // @ts-ignore
      this.$emit('updateSelectedItem', this.value); // TODO: for backward compatibility
    },
  },
});
