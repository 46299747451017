




















import Vue, { PropType } from 'vue';
import noData from '../no-data/no-data.vue';

export default Vue.extend({
  components: {
    noData,
  },
  props: {
    id: {
      type: String,
      required: true,
      validator: (value) => value != null && value.length > 0,
    },
    showNoData: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String as PropType<'table' | 'list'>,
      required: false,
      default: 'table',
    },
  },
});
