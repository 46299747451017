







import Vue from 'vue';
import editor from '../editor.vue';
import selector from '../selector/selector.vue';

interface State {
  useDefaultAvatar: boolean;
}

export default Vue.extend({
  components: {
    editor,
    selector,
  },

  data():State {
    return {
      useDefaultAvatar: true,
    };
  },

  methods: {
    setAvatar(idOrContent:number|string) {
      this.$emit('set-avatar', idOrContent);
    },
    clearAvatar() {
      this.$emit('clear-avatar');
    },
    pickCustomAvatar() {
      this.useDefaultAvatar = false;
    },
  },
});
