import { Module } from 'vuex';
import type { LicensePlanModule, Store } from '@/main/store/store-types';
import { LicensePricingPlan } from '@/generated/api-client';

const planDuration = <Record<number, string>> {
  14: '14 days',
  365: '1 year',
  36500: 'unlimited',
};

const licensePlan: Module<LicensePlanModule, Store> = {
  namespaced: true,

  state: (): LicensePlanModule => ({
    plan: null,
  }),

  getters: {
    getPlanId(state): LicensePricingPlan['id'] | null {
      return state.plan ? state.plan.id : null;
    },
    getPlan(state): LicensePlanModule['plan'] {
      return state.plan;
    },
    getPlanDuration(state): string {
      if (!state.plan) {
        return '';
      }
      return planDuration[state.plan.period];
    },
  },

  mutations: {
    setPlan(state, plan: LicensePlanModule['plan']): void {
      state.plan = plan;
    },
  },
};

export default licensePlan;
