












import Vue from 'vue';
import nodata from '../no-data/no-data.vue';

export default Vue.extend({

  components: {
    nodata,
  },
});
