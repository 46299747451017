




import Vue from 'vue';
import VueTypes from 'vue-types';
import statusBox from './status-box.vue';
import type { StatusBox } from './status-box.vue';

interface State {
  licenseStatus: StatusBox;
}

export default Vue.extend({
  props: {
    status: VueTypes.string.isRequired,
  },

  components: {
    statusBox,
  },

  data(): State {
    return {
      licenseStatus: {
        pending: {
          color: '#007AFF',
          backgroundColor: 'rgba(0, 122, 255, 0.05)',
        },
        active: {
          color: '#2CC77C',
          backgroundColor: 'rgba(44, 199, 124, 0.05)',
        },
        expired: {
          color: '#FF6600',
          backgroundColor: 'rgba(255, 102, 0, 0.05)',
        },
      },
    };
  },
});
