// Errors of this type to be ignored in global errorHandler
class HandledError extends Error {
  cause: Error;

  constructor(cause: Error) {
    super();
    this.cause = cause;
  }
}

export default HandledError;
