









import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#C7C7CC',
    },
    width: {
      type: String,
      default: '73',
    },
    height: {
      type: String,
      default: '20',
    },
  },
});
