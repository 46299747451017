import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import checkbox from '../checkbox/checkbox.vue';
import { SortArrow } from '@/components/icon/icons';
import type { Header } from './header';
import { sorting } from './header';

interface State {
  sortingOrder: -1 | 1;
  indexOfSelectedHeader: number;
  indexOfSelectedHeaderSm: number;
  indexOfSelectedHeaderMd: number;
  indexOfSelectedHeaderLg: number;
}

export default Vue.extend({
  components: {
    checkbox,
    SortArrow,
  },

  props: {
    headers: {
      type: Array as PropType<Array<Header<any, any>>>,
      required: true,
    },
  },

  data(): State {
    return {
      sortingOrder: -1,
      indexOfSelectedHeader: 0,
      indexOfSelectedHeaderSm: 0,
      indexOfSelectedHeaderMd: 0,
      indexOfSelectedHeaderLg: 0,
    };
  },

  mounted(): void {
    this.headers.forEach((h, i) => {
      if (h.defaultSorting && h.defaultSorting.length) {
        h.defaultSorting.forEach((s: string) => {
          if (s === 'sm') {
            this.indexOfSelectedHeaderSm = i;
          }
          if (s === 'md') {
            this.indexOfSelectedHeaderMd = i;
          }
          if (s === 'lg') {
            this.indexOfSelectedHeaderLg = i;
          }
        });
      }
    });
    this.setSortingDetails();
  },

  methods: {
    sortBy(i: number) {
      const header = this.headers[i];
      if (!header.sortName) {
        return;
      }
      this.sortingOrder = this.indexOfSelectedHeader === i
        ? this.sortingOrder === -1
          ? 1
          : -1
        : 1;

      this.indexOfSelectedHeader = i;
      this.$emit('sorting-changed', sorting(this.headers[i].sortName, this.sortingOrder));
    },
    getClassObject(i: number) {
      return {
        active: this.indexOfSelectedHeader === i,
        [this.headers[i].hide]: true,
      };
    },
    setSortingDetails(): void {
      if (this.$screen.breakpoint === 'sm') {
        this.indexOfSelectedHeader = this.indexOfSelectedHeaderSm;
        this.sortingOrder = this.headers[this.indexOfSelectedHeaderSm].defaultOrder || -1;
      }
      if (this.$screen.breakpoint === 'md') {
        this.indexOfSelectedHeader = this.indexOfSelectedHeaderMd;
        this.sortingOrder = this.headers[this.indexOfSelectedHeaderMd].defaultOrder || -1;
      }
      if (this.$screen.breakpoint === 'lg') {
        this.indexOfSelectedHeader = this.indexOfSelectedHeaderLg;
        this.sortingOrder = this.headers[this.indexOfSelectedHeaderLg].defaultOrder || -1;
      }
    },
    ...mapActions('historyActions', ['toggleSelectableItems']),
  },

  computed: {
    arrowUp(): boolean {
      return this.sortingOrder === 1;
    },
    ...mapGetters('historyActions', ['getEnableSelection', 'getSelectAllStatus']),
  },

  watch: {
    '$screen.breakpoint': function f(): void {
      this.setSortingDetails();
      this.$emit('sorting-changed', sorting(this.headers[this.indexOfSelectedHeader].sortName, this.sortingOrder));
    },
  },
});
