import Vue, { PropType } from 'vue';
import {
  ArrowDown, ArrowLeft, ArrowRight, FirstPage, LastPage,
} from '../icon/icons';
import { createPaginationOptions, PageSize, Paging } from './pagination-type';

const range = (start: number, length: number) => new Array(length).fill(0).map((v, i) => (i + start));

export default Vue.extend({
  components: {
    FirstPage,
    LastPage,
    ArrowLeft,
    ArrowRight,
    ArrowDown,
  },

  props: {
    totalCount: {
      type: Number,
      required: true,
      validator: (value: number) => value > 0 && Number.isInteger(value),
    },
    paging: {
      type: Object as PropType<Paging>,
      required: true,
    },
  },

  data() {
    return {
      itemsPerPageOptions: createPaginationOptions(),
    };
  },

  computed: {
    itemsPerPage(): number {
      return this.paging.itemsPerPage;
    },
    selectedRange(): string {
      const { currentPage, itemsPerPage } = this.paging;
      const rangeStart = ((currentPage - 1) * itemsPerPage) + 1;
      const rangeEnd = currentPage * itemsPerPage;
      return `${rangeStart}-${rangeEnd > this.totalCount ? this.totalCount : rangeEnd}`;
    },
    numberOfPages(): number {
      const { itemsPerPage } = this.paging;
      const itemsOnLastPage = this.totalCount % itemsPerPage;
      const numberOfPages = Math.floor(this.totalCount / itemsPerPage);
      return itemsOnLastPage > 0 ? numberOfPages + 1 : numberOfPages;
    },
    pagesRange(): number[] {
      const { currentPage, itemsPerPage } = this.paging;
      const itemsOnLastPage = this.totalCount % itemsPerPage;
      const numberOfPages = Math.floor(this.totalCount / itemsPerPage);
      const totalPagesCount = itemsOnLastPage > 0 ? numberOfPages + 1 : numberOfPages;

      const rest = currentPage % 4;
      const group = Math.floor(currentPage / 4) - (rest === 0 ? 1 : 0);

      const currentRange = range(group === 0 ? 1 : (4 * group) + 1, 4);
      if (currentRange[currentRange.length - 1] > totalPagesCount) {
        return (totalPagesCount <= 4) ? range(1, totalPagesCount) : range(totalPagesCount - 3, 4);
      }

      return currentRange;
    },
  },

  methods: {
    nextPage(): void {
      this.page(this.paging.currentPage + 1);
    },
    previousPage(): void {
      this.page(this.paging.currentPage - 1);
    },
    page(pageNumber: number): void {
      this.$emit('paging-changed', new Paging(pageNumber, this.paging.itemsPerPage));
    },
    setItemsPerPage(value: PageSize): void {
      this.$emit('paging-changed', new Paging(1, value));
    },
    atPage(pageNumber: number): boolean {
      return this.paging.currentPage === pageNumber;
    },
  },

});
