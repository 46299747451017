




import Vue from 'vue';
import VueTypes from 'vue-types';
import statusBox from './status-box.vue';
import type { StatusBox } from './status-box.vue';

interface State {
  printingStatus: StatusBox;
}

export default Vue.extend({
  props: {
    status: VueTypes.string.isRequired,
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    statusBox,
  },

  data(): State {
    return {
      printingStatus: {
        aborted: {
          color: '#FC7030',
          backgroundColor: '#FFFBF6',
        },
        completed: {
          color: '#2CC77C',
          backgroundColor: '#F5FCF9',
        },
        failed: {
          color: '#FF3B30',
          backgroundColor: '#FFF5F5',
        },
      },
    };
  },
});
