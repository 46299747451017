import Joi from 'joi';
import { required } from 'vuelidate/lib/validators';

const email = Joi.string().email({ tlds: { allow: false }, allowUnicode: false }).max(319);

const emailValidation = (errorType: string) => (value: string): boolean => {
  const error = email.validate(value).error?.details[0].type;
  return error !== errorType;
};

const emailConstraints = {
  required,
  email: emailValidation('string.email'),
  maxLength: emailValidation('string.max'),
};

export default emailConstraints;
