import Vue, { PropType } from 'vue';
import joi from 'joi';
import base64js from 'base64-js';
import {
  PrinterStatus, PrinterData, PrinterDataRemovingActionType,
} from '@/generated/api-client';
import { printerIcons } from '@/components';
import { JobPreview } from '@/components/icon/icons';
import { formatMinutes } from '@/helpers';
import PrinterIdle from './printer-idle.svg';
import PrinterOffline from './printer-offline.svg';
import PrinterPaused from './printer-paused.svg';

const printerDetailsSchema = joi.object({
  id: joi.string().required(),
  name: joi.string().required(),
  status: joi.string().required().valid('offline', 'idle', 'printing', 'paused'),
  type: joi.string().required().valid(
    'eone', 'eoneht', 'p4kadvantage', 'p4k2.0', 'd4kpro', 'xtreme', 'einstein', 'einsteinpro', 'einsteinproxl',
    'proxl', 'other',
  ),
  serialNumber: joi.string().required(),
  businessUnitName: joi.string().required(),
  materialName: joi.string().optional(),
  removingActionType: joi.alternatives(
    PrinterDataRemovingActionType.Forbidden, PrinterDataRemovingActionType.Remove,
    PrinterDataRemovingActionType.Unassign,
  ),
  printing: joi.object({
    jobName: joi.string().required(),
    materialVolume: joi.number().required().min(0),
    completionRate: joi.number().required().min(0),
    printingTimeLeft: joi.number().required().min(0),
    preview: joi.string().optional().allow(null),
  }).optional(),
});

export default Vue.extend({
  components: {
    printerIcons,
    JobPreview,
    PrinterPaused,
    PrinterIdle,
    PrinterOffline,
  },

  props: {
    details: {
      type: Object as PropType<Required<PrinterData>>,
      required: true,
      validator: (value) => !joi.isError(printerDetailsSchema.validate(value).error),
    },
  },

  computed: {
    materialName(): string {
      if (!this.details.materialActivated) {
        return '';
      }

      if (!this.details.material) {
        return 'Material card is not set';
      }

      return !this.details.material.name ? 'Material name is not defined' : this.details.material.name;
    },
    printingMaterialVolume(): string {
      const { printing } = this.details;
      return printing.materialVolume === 0 ? '0ml' : `${Number(printing.materialVolume).toFixed(2)}ml`;
    },
    progressRate(): { width: string } {
      const { printing } = this.details;
      return { width: `${(printing.completionRate < 0.02 ? 0.02 : printing.completionRate) * 100}%` };
    },
    progressPercentage(): string {
      const { printing } = this.details;
      return `${printing.completionRate < 0.02 ? 2 : (printing.completionRate * 100).toFixed()}%`;
    },
    progressPieColor(): { background: string } {
      const color = this.details.status === PrinterStatus.Printing ? '007AFF' : 'FFB340';
      const percentage = this.progressPercentage;
      return { background: `conic-gradient(#${color} ${percentage}, #E5E5EA ${percentage})` };
    },
    timeLeft(): string {
      return `${formatMinutes(this.details.printing.printingTimeLeft)} left`;
    },
    printingLabel(): string {
      if (![PrinterStatus.Printing, PrinterStatus.Paused].includes(this.details.status)) {
        throw new Error('Trying to use printing label provider for printer that is not printed.');
      }

      return this.details.status === PrinterStatus.Printing ? 'Printing' : 'Paused';
    },
    isPrinting(): boolean {
      return this.details.status === PrinterStatus.Printing;
    },
    isPaused(): boolean {
      return this.details.status === PrinterStatus.Paused;
    },
    hasPreview(): boolean {
      return this.details.printing.preview != null && this.details.printing.preview.length > 0;
    },
    preview(): string {
      return `data:image/png;base64,${base64js.fromByteArray(Buffer.from(this.details.printing.preview, 'hex'))}`;
    },
  },

  methods: {
    handleClick(): void {
      this.$emit('click', { id: this.details.id });
    },
  },
});
