import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import { HandledError } from '@/exceptions';

type AxiosErrorHandler = (error: Error) => void;

type AxiosResponseInterceptor = (error: Error) => Promise<HandledError | Error>;

type HandlerCallback = (error: AxiosError) => void;

type HandlerMatch = {
  method?: Method | Method[];
  code?: number | number[];
  url?: RegExp;
};

type Handler = {
  callback: HandlerCallback;
  match?: {
    methods?: Method[];
    codes?: number[];
    url?: RegExp;
  };
};

const createHandlerPredicate = (response: AxiosResponse) => (h: Handler) => {
  if (h.match?.methods && !h.match.methods.includes(response.config.method!)) {
    return false;
  }
  if (h.match?.codes && !h.match.codes.includes(response.data.code)) {
    return false;
  }
  if (h.match?.url && !h.match?.url.test(response.config.url!)) {
    return false;
  }
  return true;
};

const logHttpError = (error: AxiosError) => {
  console.error(`HTTP error occurred while fetching '${error.config.url}'\n${error.message}`);
  if (error.response?.status === 400) {
    console.error(`Request validation failed with code: ${error.response.data?.code}`);
  }
};

class AxiosErrorHandlerBuilder {
  private handlers = new Map<number, Handler[]>();

  private defaultHandler: Handler | undefined;

  public when(status: number, handlerMatch: HandlerMatch | undefined, callback: HandlerCallback) {
    if (!this.handlers.has(status)) {
      this.handlers.set(status, []);
    }
    const handlers = this.handlers.get(status);

    const hm = handlerMatch;
    const match: Handler['match'] = hm && {
      methods: typeof hm.method === 'string' ? [hm.method] : hm.method,
      codes: typeof hm.code === 'number' ? [hm.code] : hm.code,
      url: hm.url,
    };
    handlers!.push({ callback, match });

    return this;
  }

  /**
  * @summary Handles AxiosError.
  * @summary Executes callback which matches or default (if any)
  * @param {Error} error any error
  * @returns {Error|HandledError} HandledError if appropriate handler was found and applied or original error otherwise
  */
  private executor(error: Error): Error {
    if (!axios.isAxiosError(error)) {
      return error;
    }
    if (!error.response) {
      console.error('Response is missing.');
      return error;
    }
    const { response } = error;
    const statusHandlers = this.handlers.get(response.status) || [];
    const handler = statusHandlers.find(createHandlerPredicate(response)) || this.defaultHandler;
    if (handler) {
      handler.callback(error);
      return new HandledError(error);
    }
    return error;
  }

  /**
  * @summary Creates error handler for Axios requests.
  * @summary Handler executes callback which matches or default (if any)
  * @summary Handler throws original error if not AxiosError or no callback was executed
  */
  public build(): AxiosErrorHandler {
    return (error: Error) => {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      logHttpError(error);
      const handled = this.executor(error);
      if (!(handled instanceof HandledError)) {
        throw handled;
      }
    };
  }

  /**
  * @summary Creates onReject handler for Axios response interceptor.
  * @summary Handler always returns rejected Promise to allow further processing of intercepted response
  * @summary Handler executes callback which matches or default (if any) and returns HandledError
  * @summary Handler rejects with original error if not AxiosError or no callback was executed
  */
  public buildInterceptor(): AxiosResponseInterceptor {
    return (error: Error) => {
      const handled = this.executor(error);
      if (handled instanceof HandledError) {
        logHttpError(handled.cause as AxiosError);
        console.info(`HTTP error was intercepted and handled: ${handled.cause.message}`);
      }
      return Promise.reject(handled);
    };
  }

  public default(callback: HandlerCallback) {
    this.defaultHandler = { callback };

    return this;
  }
}

export { AxiosErrorHandlerBuilder, AxiosErrorHandler };
