class AppError extends Error {
  constructor(message: string) {
    super();
    this.message = message;
  }

  isAppError(): boolean {
    return !!this.message;
  }
}

export default AppError;
