




import Vue from 'vue';
import { booleanProp, stringProp } from 'vue-ts-types';
import statusBox from './status-box.vue';
import type { StatusBox } from './status-box.vue';

interface State {
  tierStatus: StatusBox;
}

export default Vue.extend({
  props: {
    status: stringProp().required,
    collapsed: booleanProp().withDefault(false),
  },

  components: {
    statusBox,
  },

  data(): State {
    return {
      tierStatus: {
        inactive: {
          color: '#FC7030',
          backgroundColor: '#FFFBF6',
        },
        active: {
          color: '#2CC77C',
          backgroundColor: '#F5FCF9',
        },
      },
    };
  },
});
