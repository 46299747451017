





import Vue from 'vue';
import back from './icon/icons/back.svg';

export default Vue.extend({
  components: {
    back,
  },
  props: {
    parentRouteName: {
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$router.push({ name: this.parentRouteName });
    },
  },
});
